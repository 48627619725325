import { Form, Input, Button, Spin } from 'antd';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { Link, useHistory } from 'react-router-dom';
import { authsService } from '../../../../services';

// styles
import './forgot-password.page.scss';
import { usePageTitle } from '@/hooks/use-title';
import { YcTitle } from '../../../../components';

export function ForgotPasswordPage() {
  usePageTitle('Forgot Password');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/forgot-password',
    title: 'Forgot Password'
  });


  const [loading, _setLoading] = useState(false);

  const uHistory = useHistory();

  const onFinish = async (data: { email: string }) => {
    _setLoading(true);
    if (await authsService.forgetPassword(data)) {
      uHistory.push('/login');
    }
    _setLoading(false);
  };

  return (
    <div className="root-forgot-password">
      <Spin spinning={loading}>
        <div className="login-contain">
          <div className="m-auto">
            <div className="login-titleblock">
              <YcTitle label="Forget password"></YcTitle>
              <br />
              <p>We will send you an email with instructions on how to reset your password.</p>
              <div className="block-auth">
                <Form onFinish={onFinish} layout="vertical">
                  <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                    <Input placeholder="Email" type="email" />
                  </Form.Item>

                  <div className="row justify-content-between">
                    <div className="col-lg-3 col-12">
                      <div className="mt-4">
                        <Button className="btn-primary-network w-100" type="primary" htmlType="submit">
                          Forget Password
                        </Button>
                      </div>
                    </div>

                    {
                      <div className="col-lg-9 col-12 mt-4">
                        <div className="account-content">
                          {' '}
                          Do you remember your password?{' '}
                          <Link className="account-btn" to="/login">
                            Login{' '}
                          </Link>{' '}
                        </div>
                      </div>
                    }
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="bkg-footer" />
        </footer>
      </Spin>
    </div>
  );
}
