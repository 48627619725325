import { useMemo, useState } from 'react';
import { Spin } from 'antd';

import { YcTitle, ReportLineGraph } from "@/components";
import { YcTable, YcColumnType } from "@/components/table";
import { reportingAdvertisingService } from "@/services";
import { NumberTools } from "@/tools";
import { usePageTitle } from '@/hooks/use-title';
import ReactGA from 'react-ga4';

import { ReportingFilter, Filters, HavingFilter, AddFilter } from '../../components';

import './reporting-advertising.page.scss';

interface Total {
  impressions: number;
  clicks: number;
  installs: number;
  spend: number;
  ctr: number;
  ir: number;
  ecpi: number;
  ecpm: number;
}

interface ResultLine {
  id: number;
  impressions: number;
  clicks: number;
  installs: number;
  spend: number;
  ctr: number;
  ir: number;
  ecpi: number;
  ecpm: number;

  date?: string;
  hourOfDay?: string;
  platform?: string;
  platformVersion?: string;
  applicationKey?: string;
  campaignKey?: string;
  countryCode?: string;
  source?: string;
  placementType?: string;
  creativeType?: string;
  adName?: string;
}

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

export function ReportingAdvertisingPage(props: {}) {
  usePageTitle('Reporting - Advertising');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/reporting/advertising',
    title: 'Reporting - Advertising'
  });

  const [filters, _setFilters] = useState<Filters | null>(null);
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);

  const onSetFilters = (filters) => {
    _setFilters(filters);
  }

  const onSearch = async (data: any) => {
    _setLoading(true);
    reportingAdvertisingService.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
      }
    });
  }

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({ ...filters, [key]: filters![key].map(item => { if (item.value === value || item.label === value) { item.checked = checked; } return item; }) });
  }

  const columDate: YcColumnType<ResultLine> = {
    title: 'Date', key: 'date', dataIndex: 'date',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columHourOfDay: YcColumnType<ResultLine> = {
    title: 'Hour', key: 'hourOfDay', dataIndex: 'hourOfDay',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.hourOfDay}
      <AddFilter key="hours" item="hours" value={record.hourOfDay} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columApplication: YcColumnType<ResultLine> = {
    title: 'Application', key: 'applicationKey', dataIndex: 'applicationKey',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.applicationKey}
      <AddFilter key="applications" item="applications" value={record.applicationKey} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAdName: YcColumnType<ResultLine> = {
    title: 'Ad Name', key: 'adName', dataIndex: 'adName',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columSource: YcColumnType<ResultLine> = {
    title: 'Source', key: 'source', dataIndex: 'source',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.source}
      <AddFilter key="sources" item="sources" value={record.source} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacementType: YcColumnType<ResultLine> = {
    title: 'Placement Type', key: 'placementType', dataIndex: 'placementType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placementType}
      <AddFilter key="placementTypes" item="placementTypes" value={record.placementType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCreativeType: YcColumnType<ResultLine> = {
    title: 'Creative Type', key: 'creativeType', dataIndex: 'creativeType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.creativeType}
      <AddFilter key="creativeTypes" item="creativeTypes" value={record.creativeType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCampaign: YcColumnType<ResultLine> = {
    title: 'Campaign', key: 'campaignKey', dataIndex: 'campaignKey',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.campaignKey}
      <AddFilter key="campaigns" item="campaigns" value={record.campaignKey} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCountry: YcColumnType<ResultLine> = {
    title: 'Country', key: 'countryCode', dataIndex: 'countryCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.countryCode}
      <AddFilter key="countries" item="countries" value={record.countryCode} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatform: YcColumnType<ResultLine> = {
    title: 'Platform', key: 'platform', dataIndex: 'platform',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platform}
      <AddFilter key="platforms" item="platforms" value={record.platform} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatformVersion: YcColumnType<ResultLine> = {
    title: 'Platform Version', key: 'platformVersion', dataIndex: 'platformVersion',
    ycSort: 'numeric',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platformVersion}
      <AddFilter key="platformVersions" item="platformVersions" value={record.platformVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  function Filter({ filterKey }: { filterKey: keyof ResultLine }) {
    return <HavingFilter
      onFilter={(v, c) => _setTableData(results?.results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
      onReset={() => _setTableData(results?.results || [])}
      filterKey={filterKey}
    />;
  }

  const columns: YcColumnType<ResultLine>[] = [
    {
      title: 'Impressions', dataIndex: 'impressions', key: 'impressions', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.impressions),
      filterDropdown: <Filter filterKey='impressions' />
    },
    {
      title: 'Clicks', dataIndex: 'clicks', key: 'clicks', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.clicks),
      filterDropdown: <Filter filterKey='clicks' />,
    },
    {
      title: 'Installs', dataIndex: 'installs', key: 'installs', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.installs),
      filterDropdown: <Filter filterKey='installs' />
    },
    {
      title: 'CTR', dataIndex: 'ctr', key: 'ctr', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.ctr, 2),
      filterDropdown: <Filter filterKey='ctr' />
    },
    {
      title: 'IR', dataIndex: 'ir', key: 'ir', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.ir, 2),
      filterDropdown: <Filter filterKey='ir' />
    },
    {
      title: 'eCPI', dataIndex: 'ecpi', key: 'ecpi', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.ecpi, 3),
      filterDropdown: <Filter filterKey='ecpi' />
    },
    {
      title: 'eCPM', dataIndex: 'ecpm', key: 'ecpm', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.ecpm, 3),
      filterDropdown: <Filter filterKey='ecpm' />
    },
    {
      title: 'Spend', dataIndex: 'spend', key: 'spend', defaultSortOrder: 'descend', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.spend, 3),
      filterDropdown: <Filter filterKey='spend' />
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: YcColumnType<ResultLine>[] = [...columns];
    let bCols: YcColumnType<ResultLine>[] = [];
    if (results?.is.includes("creativeType")) {
      bCols = [columCreativeType, ...bCols];
    }
    if (results?.is.includes("placementType")) {
      bCols = [columPlacementType, ...bCols];
    }
    if (results?.is.includes("ad")) {
      bCols = [columAdName, ...bCols];
    }
    if (results?.is.includes("source")) {
      bCols = [columSource, ...bCols];
    }
    if (results?.is.includes("country")) {
      bCols = [columCountry, ...bCols];
    }
    if (results?.is.includes("campaign")) {
      bCols = [columCampaign, ...bCols];
    }
    if (results?.is.includes("application")) {
      bCols = [columApplication, ...bCols];
    }
    if (results?.is.includes("platform")) {
      bCols = [columPlatform, ...bCols];
    }
    if (results?.is.includes("platformVersion")) {
      bCols = [columPlatformVersion, ...bCols];
    }
    if (results?.is.includes("hourOfDay")) {
      bCols = [columHourOfDay, ...bCols];
    }
    if (results?.is.includes("granularity")) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        ycCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
    { key: 'clicks', value: NumberTools.largeNumber(results!.total.clicks) },
    { key: 'installs', value: NumberTools.largeNumber(results!.total.installs) },
    { key: 'ctr', value: NumberTools.roundPercentage(results!.total.ctr, 2) },
    { key: 'ir', value: NumberTools.roundPercentage(results!.total.ir, 2) },
    { key: 'ecpi', value: NumberTools.roundPrice(results!.total.ecpi, 3) },
    { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
    { key: 'spend', value: NumberTools.roundPrice(results!.total.spend, 3) },
  ] : undefined;

  return (
    <div id='reporting-advertising'>
      <YcTitle label="Reporting - Advertising" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter service={reportingAdvertisingService} onSearch={onSearch} onSetFilters={onSetFilters} filters={filters} type="advertising"></ReportingFilter>
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['spend']} />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-advertising"
              rowKey="id"
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
