import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

import { useAuths } from '@/contexts/auths.context';
import { usePageTitle } from '@/hooks/use-title';
import { YcContactUsBlock, YcFooter } from '@/components';

import './home.page.scss';

function GetStartedNow() {
  const uAuths = useAuths();
  return (
    <>
      {!uAuths.isLogin() && (
        <Link to="login" type="button" className="btn btn-primary-network">
          GET STARTED NOW
        </Link>
      )}
    </>
  );
}

function ContactUsNow() {
  const uAuths = useAuths();
  return (
    <>
      {!uAuths.isLogin() && <YcContactUsBlock />}
    </>
  );
}

export function HomePage() {
  usePageTitle('Grow your UA/Monet (user acquisition/revenue)');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/grow',
    title: 'Grow your UA/Monet (user acquisition/revenue)'
  });

  return (
    <div id="root-home">
      <div className="header-contain">
        <div className="row container m-auto reverse">
          <div className="col-xl-4 col-lg-5 col-md-12 header-titleblock">
            <div>
              <h1>Optimize your advertising performance with our smart network</h1>
              <br />
              <p>Trust our YSO Network advertising network to perform on the best placements suited to your mobile games.</p>
              <br />
              <GetStartedNow />
            </div>
          </div>

          <div className="col-xl-8 col-lg-7 col-md-12">
            <img alt="keyvisual YSO Network" className="keyvisual" src="/homepage/header-visual.svg" />
          </div>
        </div>
      </div>

      <div className="row container m-auto mt-5">
        <div className="title-section text-center mb-3">Solutions</div>
        <div className="col-lg-4 col-md-12 solutions-contain">
          <div className="solutions-style">
            <img alt="ua" className="keyvisual mb-3" src="/homepage/ua-visual.svg" />
            <h2>User Acquisition</h2>
            <p>We'll help you reach and move the right users with our mobile gaming network.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-12 solutions-contain">
          <div className="solutions-style">
            <img alt="monet" className="keyvisual mb-3" src="/homepage/monet-visual.svg" />
            <h2>Monetization</h2>
            <p>Optimize and increase the monetization of your ad inventories</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-12 solutions-contain">
          <div className="solutions-style">
            <img alt="exchange" className="keyvisual mb-3" src="/homepage/exchange-visual.svg" />
            <h2>Ad Exchange</h2>
            <p>Benefit from the best locations negotiated at the lowest cost for your ads</p>
          </div>
        </div>
      </div>

      <div className="row container m-auto mt-5">
        <div className="col-lg-12 solutions-contain">
          <div className="solutions-style">
            <div className="row">
              <div className="col-lg-6">
                <img alt="ua" className="keyvisual" src="/homepage/ads-visual.svg" />
              </div>
              <div className="col-lg-6 mt-3">
                <h2>Ads formats</h2>
                <p>distribute your ads on three powerful and essential formats: interstitials, banners and rewards.</p>
                <p>These formats are complementary and adaptable to all types of ads.</p>
                <br />
                <GetStartedNow />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactUsNow />

      <YcFooter/>
    </div>
  );
}
