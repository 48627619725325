import Avatar from 'antd/lib/avatar/avatar';
import { Link } from 'react-router-dom';
import { useAuths } from '@/contexts/auths.context';
import { Button, Drawer, Menu, Popover, Grid } from 'antd';
import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';

// styles
import './header.scss';
import { Permissions } from '@/modeles';

const { useBreakpoint } = Grid;

function showNode(permissions: Permissions | null, node: string) {
  return permissions?.getAsArray().some(p => p.includes(node));
}

function showDocManagementNode(permissions: Permissions | null) {
  return ['applications/documentation', 'advertising/campaigns/documentation', 'publishing/placements/documentation'].some(p => permissions?.isAllowed(p));
}

function showDocReportsNode(permissions: Permissions | null) {
  return ['advertising/reports/documentation', 'publishing/reports/documentation', 'bidding/reports/documentation'].some(p => permissions?.isAllowed(p));
}

export function YcHeader() {
  const uAuths = useAuths();

  const onLogout = async () => {
    await uAuths.logout();
  }

  const { xs, sm } = useBreakpoint();

  const [showMenu, setShowMenu] = useState<{ visible: boolean; }>({ visible: false });

  const handleShowMenu = (visible: boolean) => {
    setShowMenu({ visible });
  };

  const Navigation = ({ mode }) => {
    return (
      <Menu key={`menu-${mode}`} mode={mode}>
        {uAuths.isLogin() &&
          <>
            <Menu.Item key="menu-item-dashboard">
              <Link to="/dashboard" onClick={() => handleShowMenu(false)}>Dashboard</Link>
            </Menu.Item>
            {showNode(uAuths.permissions, 'applications/read') && (
              <Menu.Item key="menu-item-apps">
                <Link to="/apps" onClick={() => handleShowMenu(false)}>Applications</Link>
              </Menu.Item>
            )}
            {showNode(uAuths.permissions, 'advertising/campaigns/read') && (
              <Menu.Item key="menu-item-advertising">
                <Link to="/advertising" onClick={() => handleShowMenu(false)}>Advertising <small>UA / Campaigns</small></Link>
              </Menu.Item>
            )}
            {showNode(uAuths.permissions, 'publishing/placements/read') && (
              <Menu.Item key="menu-item-publishing">
                <Link to="/publishing" onClick={() => handleShowMenu(false)}>Publishing <small>Monet / Placements</small></Link>
              </Menu.Item>
            )}
            {showNode(uAuths.permissions, 'bidding/settings/read') && (
              <Menu.Item key="menu-item-bidding">
                <Link to="/bidding/bidder-config" onClick={() => handleShowMenu(false)}>Bidding <small>Bidder / Settings</small></Link>
              </Menu.Item>
            )}
            {showNode(uAuths.permissions, 'reports/read') && <Menu.SubMenu key="menu-sub-reporting" title="Reporting">
              {showNode(uAuths.permissions, 'advertising/reports/read') && (
                <Menu.Item key="menu-item-reporting-advertising">
                  <Link to="/reporting/advertising" onClick={() => handleShowMenu(false)}>Advertising</Link>
                </Menu.Item>
              )}
              {showNode(uAuths.permissions, 'publishing/reports/read') && (
                <Menu.Item key="menu-item-reporting-publishing">
                  <Link to="/reporting/publishing" onClick={() => handleShowMenu(false)}>Publishing</Link>
                </Menu.Item>
              )}
              {showNode(uAuths.permissions, 'bidding/reports/read') && (
                <Menu.Item key="menu-item-reporting-bidding">
                  <Link to="/reporting/bidding" onClick={() => handleShowMenu(false)}>Bidding</Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>}
            {showNode(uAuths.permissions, 'documentation') && <Menu.SubMenu key="menu-sub-documentation" title="Docs">
              {showNode(uAuths.permissions, 'documentation/unity') && (
                <Menu.ItemGroup key="menu-item-group-sdk" title="SDK">
                  <Menu.Item key="menu-item-group-sdk-unity">
                    <Link to="/docs/sdk/unity" onClick={() => handleShowMenu(false)}>Sdk Unity</Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              )}
              {showDocManagementNode(uAuths.permissions) && <Menu.ItemGroup key="menu-item-group-campaign-management" title="Campaign Management">
                {showNode(uAuths.permissions, 'applications/documentation') && (
                  <Menu.Item key="menu-item-group-campaign-management-apps">
                    <Link to="/docs/manage/apps" onClick={() => handleShowMenu(false)}>Manage Applications</Link>
                  </Menu.Item>
                )}
                {showNode(uAuths.permissions, 'advertising/campaigns/documentation') && (
                  <>
                    <Menu.Item key="menu-item-group-campaign-management-campaigns">
                      <Link to="/docs/manage/campaigns" onClick={() => handleShowMenu(false)}>Manage Campaigns</Link>
                    </Menu.Item>
                    <Menu.Item key="menu-item-group-campaign-management-creatives">
                      <Link to="/docs/manage/creatives" onClick={() => handleShowMenu(false)}>Manage Creatives</Link>
                    </Menu.Item>
                  </>
                )}
                {showNode(uAuths.permissions, 'publishing/placements/documentation') && (
                  <Menu.Item key="menu-item-group-campaign-management-placements">
                    <Link to="/docs/manage/placements" onClick={() => handleShowMenu(false)}>Manage Placements</Link>
                  </Menu.Item>
                )}
              </Menu.ItemGroup>}
              {showDocReportsNode(uAuths.permissions) && <Menu.ItemGroup key="menu-item-group-report" title="Report">
                {showNode(uAuths.permissions, 'advertising/reports/documentation') && (
                  <Menu.Item key="menu-item-group-report-advertising">
                    <Link to="/docs/report/advertising" onClick={() => handleShowMenu(false)}>Report Advertising</Link>
                  </Menu.Item>
                )}
                {showNode(uAuths.permissions, 'publishing/reports/documentation') && (
                  <Menu.Item key="menu-item-group-report-publishing">
                    <Link to="/docs/report/publishing" onClick={() => handleShowMenu(false)}>Report Publishing</Link>
                  </Menu.Item>
                )}
                {showNode(uAuths.permissions, 'bidding/reports/documentation') && (
                  <Menu.Item key="menu-item-group-report-bidding">
                    <Link to="/docs/report/bidding" onClick={() => handleShowMenu(false)}>Report Bidding</Link>
                  </Menu.Item>
                )}
              </Menu.ItemGroup>}
            </Menu.SubMenu>}
            {uAuths.isSuperAdmin() &&
              <Menu.SubMenu key="menu-sub-admin" title="Superadmin">
                <Menu.SubMenu key="menu-sub-reporting-superadmin" title="Reporting">
                  <Menu.Item key="menu-item-reporting-superadmin-advertising">
                    <Link to="/superadmin/reporting/advertising" onClick={() => handleShowMenu(false)}>Advertising</Link>
                  </Menu.Item>
                  <Menu.Item key="menu-item-reporting-superadmin-ssp">
                    <Link to="/superadmin/reporting/ssp" onClick={() => handleShowMenu(false)}>Bidding / Publishing</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="menu-sub-admin-control-panel" title="Control Panel">
                  <Menu.Item key="menu-item-admin-control-panel-today">
                    <Link to="/superadmin/control-panel-today" onClick={() => handleShowMenu(false)}>Today</Link>
                  </Menu.Item>
                  <Menu.Item key="menu-item-admin-control-panel">
                    <Link to="/superadmin/control-panel" onClick={() => handleShowMenu(false)}>Global</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="menu-item-admin-accounts">
                  <Link to="/superadmin/accounts" onClick={() => handleShowMenu(false)}>Accounts</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-advertising-campaigns">
                  <Link to="/superadmin/advertising/campaigns" onClick={() => handleShowMenu(false)}>Campaigns</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-assets">
                  <Link to="/superadmin/assets" onClick={() => handleShowMenu(false)}>Assets</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-assets-validation">
                  <Link to="/superadmin/assets-validation" onClick={() => handleShowMenu(false)}>Assets Validation</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-ads">
                  <Link to="/superadmin/ads" onClick={() => handleShowMenu(false)}>Ads</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-ads-learning">
                  <Link to="/superadmin/ads-learning" onClick={() => handleShowMenu(false)}>Ads learning</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-monitoring">
                  <Link to="/superadmin/monitoring" onClick={() => handleShowMenu(false)}>Monitoring</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-unified-apps">
                  <Link to="/superadmin/unified-apps" onClick={() => handleShowMenu(false)}>Unified Apps</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-bonuses">
                  <Link to="/superadmin/bonuses" onClick={() => handleShowMenu(false)}>Bonuses</Link>
                </Menu.Item>
                <Menu.Item key="menu-item-admin-platfrom-actions">
                  <Link to="/superadmin/platform-actions" onClick={() => handleShowMenu(false)}>Actions History</Link>
                </Menu.Item>
              </Menu.SubMenu>
            }
            {uAuths.originalUser && <Menu.Item key="menu-item-login-as" style={{ backgroundColor: '#ffc370' }}>
              Logged in as {uAuths.user?.email}
              <small><Button type="link" onClick={() => uAuths.logoutAs()}>Back to {uAuths.originalUser?.email || 'you'}</Button></small>
            </Menu.Item>}
            <Menu.Item key="menu-item-home-account">
              <Popover
                placement="bottomRight"
                title={<>
                  <div><b>{uAuths.user?.Account.name}</b></div>
                  {uAuths.user?.email}
                </>}
                content={
                  <ul className="list-group list-group-flush">
                    {showNode(uAuths.permissions, 'settings/informations/read') && (
                      <li className="list-group-item">
                        <Link to="/profile" onClick={() => handleShowMenu(false)}>Profile</Link>
                      </li>
                    )}
                    {uAuths.isAdmin() && showNode(uAuths.permissions, 'userManagment/read') && (
                      <li className="list-group-item">
                        <Link to="/users" onClick={() => handleShowMenu(false)}>Users</Link>
                      </li>
                    )}
                    {/* {uAuths.isAdmin() && (
                      <li className="list-group-item">
                        <Link to="/platform-actions" onClick={() => handleShowMenu(false)}>Actions History</Link>
                      </li>
                    )} */}
                    <li className="list-group-item">
                      <Link to="/login" onClick={() => {
                        handleShowMenu(false);
                        onLogout().catch(()=>{});
                      }}>Logout</Link>
                    </li>
                  </ul>
                }
                trigger="click"
              >
                <a href='#/'><Avatar icon={<UserOutlined />} /></a>
              </Popover>
            </Menu.Item>
          </>
        }
        {!uAuths.isLogin() &&
          <>
            {/* <Menu.SubMenu key="menu-sub-home-solutions" title="Solutions">
              <Menu.Item key="menu-item-home-solutions-acquisition">
                <Link to="/acquisition" onClick={() => handleShowMenu(false)}>Acquisition</Link>
              </Menu.Item>
              <Menu.Item key="menu-item-home-solutions-monetization">
                <Link to="/monetization" onClick={() => handleShowMenu(false)}>Monetization</Link>
              </Menu.Item>
              <Menu.Item key="menu-item-home-solutions-exchange">
                <Link to="/ad-exchange" onClick={() => handleShowMenu(false)}>Ad Exchange</Link>
              </Menu.Item>
              <Menu.Item key="menu-item-home-solutions-formats">
                <Link to="/ads-formats" onClick={() => handleShowMenu(false)}>Ads Formats</Link>
              </Menu.Item>
            </Menu.SubMenu> */}
            <Menu.Item key="menu-item-home-contact-us">
              <Link className='contact-us' to="/contact-us" onClick={() => handleShowMenu(false)}>Contact Us</Link>
            </Menu.Item>
            <Menu.Item key="menu-item-home-about">
              <Link className='about' to="/about" onClick={() => handleShowMenu(false)}>About</Link>
            </Menu.Item>
            <Menu.Item key="menu-item-home-login">
              <Link className='login' to="/login" onClick={() => handleShowMenu(false)}>Login / Sign up</Link>
            </Menu.Item>
          </>
        }
      </Menu>
    );
  }

  return (
    <>
      <nav id="header-component" className="navbar">
        <div className='container'>
          <Link to="/">
            <img title="YSO Network" alt="Logo YSO Network" src="/logo-ysonetwork.svg" />
          </Link>
          {sm && <div className="menu-horizontal">
            <Navigation mode="horizontal" />
          </div>}
          <div className="menu-inline">
            {xs && <>
              <Button type="primary" onClick={() => handleShowMenu(true)} icon={<MenuOutlined />}></Button>
              <Drawer
                title="YSO Corp"
                placement="right"
                closable={true}
                onClose={() => handleShowMenu(false)}
                open={showMenu.visible}
              >
                <Navigation mode="inline" />
              </Drawer>
            </>}
          </div>
        </div>
      </nav>
    </>
  );
}
