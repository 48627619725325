import moment from "moment";
import ReactGA from 'react-ga4';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Button, Spin, Input, Tooltip } from 'antd';
import { MPlacement } from '@/modeles';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { placementsService } from '@/services';
import { AndroidFilled, AppleFilled, ArrowRightOutlined, PlayCircleOutlined, EditOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { YcDatePicker, YcIconName, YcTitle } from '@/components';

import './placements.page.scss';
import { ColumnType } from "antd/lib/table";
import { e_PlacementType } from '@/enums';
import { reportingPublishingService } from '@/services';
import { usePageTitle } from "@/hooks/use-title";
import { NumberTools, FilterTool } from '@/tools';

const { Search } = Input;

const Filters = ({ onSearch }) => (
  <>
    <div className="row filters">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Search placeholder="Filter by app name or bundle" onChange={onSearch} className='search-bar' />
      </div>
    </div>
  </>
);

export function PlacementsPage() {
  usePageTitle('Placements');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/publishing/placements',
    title: 'Placements'
  });

  const [placements, _setPlacements] = useState<MPlacement[]>([]);
  const [displayPlacements, setDisplayPlacements] = useState<MPlacement[]>([]);
  const [loading, _setLoading] = useState(false);
  const [hasReporting, _setHasReporting] = useState<boolean>(false);
  const [dates, _setDates] = useState<[moment.Moment, moment.Moment]>([moment().subtract(1, 'day'), moment().subtract(1, 'day')]);
  const [total, _setTotal] = useState({
    requests: 0,
    responses: 0,
    impressions: 0,
    ecpm: 0,
    revenue: 0,
  });
  const [filters, _setFilters] = useState({
    search: '',
  });


  const uLocation = useLocation();
  const uHistory = useHistory();

  useEffect(() => {
    const search = new URLSearchParams(uLocation.search);
    if (search.has('dateStart')) {
      dates[0] = moment(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = moment(search.get('dateEnd'));
    }
    _setLoading(true);
    placementsService.listing(getParams()).then((data) => {
      _setPlacements(data || []);
      // @ts-ignore
      onSearch({ target: { value: '' } });
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const displayCampaigns = placements.filter((c) => {
      let checkSearch = true;
      if (filters.search) {
        const checkName = c.name.toLowerCase().includes(filters.search?.toLowerCase());
        const checkBundle = c.applicationKey?.toLowerCase().includes(filters.search?.toLowerCase());
        const checkKey = c.key.toLowerCase().includes(filters.search?.toLowerCase());
        const checkAppName = c.Application?.name?.toLowerCase().includes(filters.search?.toLowerCase());
        const checkAppKey = c.Application?.key?.toLowerCase().includes(filters.search?.toLowerCase());

        checkSearch = checkName || checkBundle || checkKey || checkAppName || checkAppKey || false;
      }

      return checkSearch;
    });
    setDisplayPlacements(displayCampaigns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getParams = () => {
    return {
      dateStart: moment(dates[0]).format("YYYY-MM-DD"),
      dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
    }
  }

  const onLoadStats = async () => {
    uHistory.push({
      search: new URLSearchParams(getParams()).toString(),
    });
    _setLoading(true);
    const data = await reportingPublishingService.search({
      ...getParams(),
      is: "placement",
    });
    if (data) {
      if (data && data.total) {
        _setTotal(data.total);
      }
      if (data.results && data.results.length) {
        for (let placement of placements) {
          placement.requests = 0;
          placement.responses = 0;
          placement.impressions = 0;
          placement.ecpm = 0;
          placement.revenue = 0;
          for (let res of data.results) {
            if (res.placement === placement.key) {
              placement.requests = res.requests;
              placement.responses = res.responses;
              placement.impressions = res.impressions;
              placement.ecpm = res.ecpm;
              placement.revenue = res.revenue;
            }
          }
        }
        _setPlacements([...placements]);
      }
      _setHasReporting(true);
    }
    _setLoading(false);
  }

  const StatusCell = useCallback((text: string, placement: MPlacement) => (
    <Tooltip title={placement.isActive ? 'Inactive' : 'Active'}>
      <div className="block-bt link-withicon" onClick={async () => {
        if (placement) {
          if (await placementsService.activate(placement.key, !placement.isActive)) {
            placement.isActive = !placement.isActive;
            _setPlacements([...placements]);
          }
        }
      }}>
        {placement.isActive && <div><PlayCircleOutlined style={{ color: 'green' }} /> <b>Active</b><PauseCircleOutlined style={{ color: 'red' }} className="lw-icon" /></div>}
        {!placement.isActive && <div><PauseCircleOutlined style={{ color: 'red' }} /> <b>Inactive</b><PlayCircleOutlined style={{ color: 'green' }} className="lw-icon" /></div>}
      </div>
    </Tooltip>
  ), [_setPlacements, placements]);

  const columnsReporting = {
    title: 'Reporting',
    children: [
      { title: 'Requests', dataIndex: 'requests', key: 'requests', sorter: (a: MPlacement, b: MPlacement) => a.requests - b.requests },
      { title: 'Responses', dataIndex: 'responses', key: 'responses', sorter: (a: MPlacement, b: MPlacement) => a.responses - b.responses },
      {
        title: 'Impressions', dataIndex: 'impressions', key: 'impressions', defaultSortOrder: 'descend',
        sorter: (a: MPlacement, b: MPlacement) => a.impressions - b.impressions,
      },
      {
        title: 'eCPM', dataIndex: 'ecpm', key: 'ecpm',
        sorter: (a: MPlacement, b: MPlacement) => a.ecpm - b.ecpm,
        render: (text: string, record: MPlacement) => NumberTools.roundPrice(record.ecpm, 2),
      },
      {
        title: 'Revenue', dataIndex: 'revenue', key: 'revenue',
        sorter: (a: MPlacement, b: MPlacement) => a.revenue - b.revenue,
        render: (text: string, record: MPlacement) => NumberTools.roundPrice(record.revenue, 2),
      },
    ]
  };

  const columns = useMemo<ColumnType<MPlacement>[]>(() => [
    {
      title: 'Name', key: 'name',
      render: (text: string, record: MPlacement) => (
        <Tooltip title="Edit">
          <Link to={"/publishing/placements/placement/" + record.key} className='link-withicon'>
            {record.name}
            <EditOutlined className='lw-icon' />
          </Link>
        </Tooltip>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    { title: 'Key', key: 'key', dataIndex: "key", sorter: (a, b) => a.key.localeCompare(b.key || '') || -1 },
    {
      title: 'Application', key: 'applicationId',
      render: (text: string, record: MPlacement) => (
        <Tooltip title="Go to application">
          <Link to={"/apps/app/" + record.Application?.key} className='link-withicon'>
            <YcIconName app={record.Application}></YcIconName>
            <ArrowRightOutlined rotate={-45} className='lw-icon' />
          </Link>
        </Tooltip>
      ),
      sorter: (a, b) => a.Application?.name.localeCompare(b.Application?.name || '') || -1,
    },
    {
      title: 'Platform', key: 'platform',
      render: (text: string, record: MPlacement) => (
        <>
          {record.Application?.platform === "android" && <AndroidFilled />}
          {record.Application?.platform === "ios" && <AppleFilled />}
          &nbsp;{record.Application?.platform}
        </>
      ),
      sorter: (a, b) => a.Application?.platform.localeCompare(b.Application?.platform || '') || -1,
      filters: [{ text: 'Android', value: 'android' }, { text: 'iOS', value: 'ios' }],
      onFilter: (value, record) => record.Application?.platform?.toLowerCase() === (value as string).toLowerCase(),
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type || '') || -1,
      filters: [
        { text: 'Interstitial', value: e_PlacementType.INTERSTITIAL },
        { text: 'Banner', value: e_PlacementType.BANNER },
        { text: 'Rewarded', value: e_PlacementType.REWARDED },
      ],
      onFilter: (value, record) => record.type?.toLowerCase() === (value as string).toLowerCase(),
    },
    {
      title: 'Status', dataIndex: 'isActive', key: 'isActive',
      sorter: (a: MPlacement, b: MPlacement) => Number(a.isActive) - Number(b.isActive),
      render: StatusCell,
      filters: FilterTool.BOOL_filters('Active', 'Inactive'),
      onFilter: FilterTool.BOOL_onFilter('isActive'),
    },
  ], [StatusCell]);

  const getColumns = () => {
    if (hasReporting) {
      return [{
        title: 'Placement',
        children: columns.slice(0, columns.length - 1)
      },
        columnsReporting,
      columns[columns.length - 1]
      ];
    }
    return columns;
  }

  const summary = (): React.ReactNode => {

    const { totalIsActive } = placements.reduce((acc, c) => {
      acc.totalIsActive += Number(c.isActive);
      return acc;
    }, { totalIsActive: 0 });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={5}><b>Total</b></Table.Summary.Cell>
          {hasReporting &&
            <>
              <Table.Summary.Cell index={6}>{total.requests}</Table.Summary.Cell>
              <Table.Summary.Cell index={7}>{total.responses}</Table.Summary.Cell>
              <Table.Summary.Cell index={8}>{total.impressions}</Table.Summary.Cell>
              <Table.Summary.Cell index={9}>{NumberTools.roundPrice(total.ecpm, 2)}</Table.Summary.Cell>
              <Table.Summary.Cell index={10}>{NumberTools.roundPrice(total.revenue, 2)}</Table.Summary.Cell>
            </>
          }
          <Table.Summary.Cell index={hasReporting ? 11 : 6}><b>Active {totalIsActive} / {placements.length}</b></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    _setFilters({ ...filters, search: value });
  }

  return (
    <div id='publishing-placements'>
      <YcTitle label="Placements" addUrl='/publishing/placements/placement'></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <YcDatePicker value={dates} onChange={(e) => {
            if (e && e.length >= 2) {
              _setDates([e[0]!, e[1]!]);
            }
          }}
            presets={['Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date']}
            disabledDate={d => !d || d.isAfter(moment().utc().subtract(1, 'day'))} />
          <Button type="primary" className="m-l-3" onClick={() => { onLoadStats(); }}>Reporting</Button>
        </div>
        <Filters onSearch={onSearch} />
        <Table bordered size="small" dataSource={displayPlacements} columns={getColumns()} summary={summary} rowKey="key"></Table>
      </Spin>
    </div>
  );
}
