import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import './unified-apps.page.scss';
import { YcIcon, YcTable, YcTitle } from '@/components';
import { unifiedAppsService } from '@/services';
import { MApp, MUnifiedApp } from '@/modeles';
import { ColumnType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { usePageTitle } from '@/hooks/use-title';

function NameCell(text: string, record: MUnifiedApp) {
  return (
    <Link to={"/superadmin/unified-apps/unified-app/" + record.id} >
      {record.name}
    </Link>
  );
}

function Apps({ apps }: { apps: MApp[]; }) {
  return (
    <div className='d-flex'>
      {apps.map(app => <div key={app.key} className='me-2'><Link to={"/apps/app/" + app.key} ><YcIcon app={app} /></Link></div>)}
    </div>
  );
}

const columns: ColumnType<MUnifiedApp>[] = [
  { key: 'name', title: 'App name', render: NameCell },
  { key: 'apps', title: 'Linked apps', render: (_, app) => <Apps apps={app.apps} /> },
];

export function SAUnifiedAppsPage() {
  usePageTitle('Unified Apps');
  const [loading, setLoading] = useState(false);
  const [unifiedApps, setUnifiedApps] = useState<MUnifiedApp[]>([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      unifiedAppsService.listing(),
    ]).then(([unified]) => {
      setUnifiedApps(unified);
      setLoading(false);
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <YcTitle label='Unified apps' addUrl='/superadmin/unified-apps/unified-app' />
      <YcTable
        bordered
        size="small"
        dataSource={unifiedApps}
        columns={columns}
        ycTableKey="unified-apps"
      />
    </Spin>
  )
}
