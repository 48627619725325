import { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { FCItem } from "@/components";

export function AddFilter(props: { item: string; value: any; filters: any; onSetFilter: (filter: any) => any; }) {
    const [item, _setItem] = useState<FCItem | null>(null);

    useEffect(() => {
      _setItem(props.filters![props.item].find(i => (i.value === props.value || i.label === props.value)));
    }, [props.filters, props.item, props.value]);

    const onSetItemFilter = useCallback(({ checked }) => {
      if (item) {
        props.onSetFilter({ key: props.item, value: props.value, checked });
        _setItem({ ...item, checked });
      }
    }, [item, props]);

    return (<div className="add-filter">
      {item && item.checked === undefined &&
        <Button size="small" type="link" icon={<PlusCircleOutlined />} onClick={() => onSetItemFilter({ checked: true })}></Button>
      }
      {item && item.checked === undefined &&
        <Button size="small" type="link" style={{ color: 'red' }} icon={<MinusCircleOutlined />} onClick={() => onSetItemFilter({ checked: false })}></Button>
      }
      {item && item.checked !== undefined &&
        <Button size="small" type="link" style={{ color: 'red' }} icon={<CloseCircleOutlined />} onClick={() => onSetItemFilter({ checked: undefined })}></Button>
      }
    </div>);
}