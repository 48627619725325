import { Spin, TableColumnType } from 'antd';

import './ads-learning.page.scss';
import { SearchBar, YcTable, YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { AdLearning, useAdsLearning } from './useAdsLearning';
import { UtilTools } from '@/tools';
import { YcEnums } from 'yc-interfaces';
import { CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const AppCell = (text: string, record: AdLearning) => <Link to={"/apps/app/" + record.content}>{record.content}</Link>;

const columns: TableColumnType<AdLearning>[] = [
  {
    title: 'Application', key: 'applicationId',
    render: AppCell,
    sorter: (a, b) => a.content?.localeCompare(b.content) || -1,
  },
  {
    title: 'Total', key: 'total',
    render: (v, r) => (!r.state || typeof r.state === 'string') ? '' : r.state.total || '',
    sorter: (a, b) => (a.state && b.state && typeof a.state !== 'string' && typeof b.state !== 'string') ? a.state.total - b.state.total : -1,
  },
  {
    title: 'Running', key: 'running',
    render: (v, r) => (!r.state || typeof r.state === 'string') ? (r.state === YcEnums.AdPhases.RUNNING ? <CheckOutlined /> : '') : UtilTools.formatPercent(r.state.running / r.state.total) || '',
    sorter: (a, b) => (a.state && b.state && typeof a.state !== 'string' && typeof b.state !== 'string') ? (a.state.running / a.state.total) - (b.state.running / b.state.total) : -1,
  },
  {
    title: 'Prerunning', key: 'prerunning',
    render: (v, r) => (!r.state || typeof r.state === 'string') ? (r.state === YcEnums.AdPhases.PRERUNNING ? <CheckOutlined /> : '') : UtilTools.formatPercent(r.state.prerunning / r.state.total) || '',
    sorter: (a, b) => (a.state && b.state && typeof a.state !== 'string' && typeof b.state !== 'string') ? (a.state.prerunning / a.state.total) - (b.state.prerunning / b.state.total) : -1,
  },
  {
    title: 'Learning', key: 'learning',
    render: (v, r) => (!r.state || typeof r.state === 'string') ? (r.state === YcEnums.AdPhases.LEARNING ? <CheckOutlined /> : '') : UtilTools.formatPercent(r.state.learning / r.state.total) || '',
    sorter: (a, b) => (a.state && b.state && typeof a.state !== 'string' && typeof b.state !== 'string') ? (a.state.learning / a.state.total) - (b.state.learning / b.state.total) : -1,
  },
  {
    title: 'Impressions', key: 'impressions',
    render: (v, r) => r.stats.impressions,
    sorter: (a, b) => a.stats.impressions - b.stats.impressions,
  },
  {
    title: 'Clicks', key: 'clicks',
    render: (v, r) => r.stats.clicks,
    sorter: (a, b) => a.stats.clicks - b.stats.clicks,
  },
  {
    title: 'Installs', key: 'installs',
    render: (v, r) => r.stats.installs,
    sorter: (a, b) => a.stats.installs - b.stats.installs,
  },
  {
    title: 'CTR', key: 'ctr',
    render: (v, r) => UtilTools.formatPercent(r.stats.clicks / r.stats.impressions),
    sorter: (a, b) => (a.stats.clicks / a.stats.impressions) - (b.stats.clicks / b.stats.impressions),
  },
  {
    title: 'IR', key: 'ir',
    render: (v, r) => UtilTools.formatPercent(r.stats.installs / r.stats.impressions),
    sorter: (a, b) => (a.stats.installs / a.stats.impressions) - (b.stats.installs / b.stats.impressions),
  },
];

export function SAAdsLearningPage() {
  usePageTitle('Ads Learning');
  const { loading, data, onSearch } = useAdsLearning();

  return (
    <div id='admin-ads-learning'>
      <Spin spinning={loading}>
        <YcTitle label="Ads Learning"></YcTitle>
        <SearchBar placeholder="Filter by app bundle" onSearch={onSearch} />
        <YcTable dataSource={data} ycTableKey='ads-learning' columns={columns} size='small'></YcTable>
      </Spin>
    </div>
  );
}
