import { usePageTitle } from '@/hooks/use-title';
import { YcDocReader, YcFooter, YcHeaderTitle } from '@/components';

import './terms.page.scss';
import ReactGA from 'react-ga4';

export function Terms() {
  usePageTitle('terms');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/terms',
    title: 'terms'
  });

  const headerTitle = <h1>Terms and Conditions</h1>

  return (
    <div id="root-terms">
      <YcHeaderTitle title={headerTitle} />
      <div className='container d-flex justify-content-center mt-5'>
        <YcDocReader file='/ysonetworkterms.html'/>
      </div>
      <YcFooter/>
    </div>
  );
}


