import { Button, Checkbox, Form, Input, Tree } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { accountsService_SA } from '../../../../services';
import { MAccount, Permissions, uniques } from '../../../../modeles';

import './account.page.scss';
import { DataNode } from 'antd/lib/tree';
import { YcTitle } from '../../../../components';
import { usePageTitle } from '@/hooks/use-title';

const META_PERMISSIONS = {
  SETTINGS: ['settings/changePassword', 'settings/informations/read', 'settings/informations/write'],
  API_ADVERTISING: ['settings/api', 'applications/documentation', 'advertising/campaigns/documentation', 'advertising/reports/documentation'],
  API_PUBLISHING: ['settings/api', 'applications/documentation', 'publishing/placements/documentation', 'publishing/reports/documentation'],
  API_BIDDING: ['settings/api', 'bidding/reports/documentation'],
  USER_MANAGMENT: ['userManagment/read', 'userManagment/write', 'userManagment/activate'],
  APPLICATIONS: ['applications/read', 'applications/write'],
  ADVERTISING: ['advertising/campaigns/read', 'advertising/campaigns/write', 'advertising/campaigns/writeStatus', 'advertising/reports/read',
    'advertising/settings/creditLine', 'advertising/settings/mmpSetup'],
  PUBLISHING: ['publishing/placements/read', 'publishing/placements/write', 'publishing/placements/writeStatus', 'publishing/reports/read'],
  BIDDING: ['bidding/settings/read', 'bidding/settings/write', 'bidding/reports/read'],
}

const BASE_PERMISSIONS = {
  ADVERTISER: uniques(META_PERMISSIONS.SETTINGS, META_PERMISSIONS.API_ADVERTISING, META_PERMISSIONS.USER_MANAGMENT, META_PERMISSIONS.APPLICATIONS, META_PERMISSIONS.ADVERTISING),
  BIDDER: uniques(META_PERMISSIONS.SETTINGS, META_PERMISSIONS.API_BIDDING, META_PERMISSIONS.USER_MANAGMENT, META_PERMISSIONS.BIDDING),
  PUBLISHER: uniques(META_PERMISSIONS.SETTINGS, META_PERMISSIONS.API_PUBLISHING, META_PERMISSIONS.USER_MANAGMENT, META_PERMISSIONS.APPLICATIONS, META_PERMISSIONS.PUBLISHING),
}

function getPermissions(p: { path: string }[]) {
  const permissions = new Permissions();
  permissions.parseFromPaths(p.map(p => p.path));

  return permissions;
}

function getKeysFromNodes(checkedNodes: DataNode[]) {
  return uniques(checkedNodes.flatMap(node => node.children ? getKeysFromNodes(node.children) : node.key));
}

export function SAAccountPage() {
  usePageTitle('Account');
  const [account, _setAccount] = useState<MAccount | null>(null);
  const [permissions, _setPermissions] = useState<Permissions | null>(null);
  const [checkedPermissions, _setCheckedPermissions] = useState<string[]>([]);

  const uParams = useParams<{ id: string; }>();
  const uHistory = useHistory();

  useEffect(() => {
    if (!!uParams.id) {
      accountsService_SA.view(Number(uParams.id)).then((a) => {
        if (a) {
          _setAccount(a);
          if (a?.Permissions) {
            const p = getPermissions(a?.Permissions);
            _setCheckedPermissions(p.getAsArray());
          };
        } else {
          uHistory.push("/superadmin/accounts");
        }
      });
    } else {
      uHistory.push("/superadmin/accounts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedPermissions = new Permissions();
    updatedPermissions.parseFromPaths(checkedPermissions);
    _setPermissions(updatedPermissions);
  }, [checkedPermissions]);

  const addPermissions = useCallback((newPaths: string[]) => {
    const updatedPermissions = new Permissions();
    updatedPermissions.parseFromPaths(checkedPermissions);
    newPaths.forEach((path) => updatedPermissions.addPermission(path));
    _setCheckedPermissions(updatedPermissions.getAsArray());
  }, [checkedPermissions])

  const onFinish = async (data: any) => {
    if (permissions) {
      data.permissions = permissions.getAsArray().map((path) => ({ path }));
    }
    const a = await accountsService_SA.edit(Number(uParams.id), data);
    if (a) {
      _setAccount(a);
    }
  };

  return (
    <div id='admin-account'>
      {!!account &&
        <>
          <YcTitle links={[{ link: "/superadmin/accounts", label: "Accounts" }]} label={account.name}></YcTitle>
          <Form onFinish={onFinish} layout='vertical'>
            <Form.Item label="Credit Line" name="creditLine" initialValue={account.creditLine}>
              <Input type="number" min="0" max="100000" step="1000" />
            </Form.Item>
            {!!permissions && (
              <div>
                <div>Permissions</div>
                <Tree
                  checkable
                  autoExpandParent={true}
                  onCheck={(keys, { checkedNodes }) => _setCheckedPermissions(getKeysFromNodes(checkedNodes))}
                  checkedKeys={checkedPermissions}
                  treeData={permissions.getAsAntd()}
                />
              </div>
            )}
            <div className='row'>
              <div className='col-4'>
                <Form.Item label={(
                  <div>
                    Advertiser
                    <Button type='link' onClick={() => addPermissions(BASE_PERMISSIONS.ADVERTISER)} size='small' style={{fontSize: '10px'}}>+base permissions</Button>
                  </div>
                )} name="isAdvertiser" initialValue={account.isAdvertiser} valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item label={(
                  <div>
                    Publisher
                    <Button type='link' onClick={() => addPermissions(BASE_PERMISSIONS.PUBLISHER)} size='small' style={{fontSize: '10px'}}>+base permissions</Button>
                  </div>
                )} name="isPublisher" initialValue={account.isPublisher} valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item label={(
                  <div>
                    Bidder
                    <Button type='link' onClick={() => addPermissions(BASE_PERMISSIONS.BIDDER)} size='small' style={{fontSize: '10px'}}>+base permissions</Button>
                  </div>
                )} name="isBidder" initialValue={account.isBidder} valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </div>
            </div>
            <Button type='primary' htmlType='submit'>Update</Button>
          </Form>
        </>
      }
    </div>
  );
}
