import { Button, Form, Input, Result, Select } from 'antd';
import { useState } from 'react';

import { usePageTitle } from '@/hooks/use-title';
import { MContact } from '@/modeles/contact.m';
import { contactService } from '@/services';
import { YcFooter, YcHeaderTitle } from '@/components';

import './contact-us.page.scss';
import ReactGA from 'react-ga4';

export function ContactUs() {
  usePageTitle('Contact Us');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/contact-us',
    title: 'Contact Us'
  });

  const [contacted, _setContacted] = useState(false);

  const onFinish = (contact: MContact) => {
    contactService.newContact(contact);
    _setContacted(true);
  };

  return (
    <div id="contact-us">
      <YcHeaderTitle title={
        <div>
          <h1>Get in touch with us for</h1>
          <h1>personalized assistance and support!</h1>
        </div>
      } />

      <div className='container d-flex justify-content-center mt-5'>
        {!contacted ? (
          <Form
            className='contact-form'
            onFinish={onFinish}
            layout="vertical">
            <Form.Item
              rules={[
                { required: true },
                { type: 'string' },
              ]}
              hasFeedback
              label="Full name"
              name="name"
            >
              <Input placeholder="Your full name" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true },
                { type: 'email' },
              ]}
              hasFeedback
              label="Email"
              name="email"
            >
              <Input placeholder="Your email address" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true },
                { type: 'string' },
              ]}
              hasFeedback
              label="Company"
              name="company"
            >
              <Input placeholder="Your company's name" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true },
                { type: 'string' },
              ]}
              hasFeedback
              label="Subject"
              name="subject"
            >
              <Select placeholder="Select your inquiry">
                <Select.Option key='ua' value='ua'>User Acquisition / Advertising</Select.Option>
                <Select.Option key='monet' value='monet'>Monetization / Publishing</Select.Option>
                <Select.Option key='all' value='all'>Both</Select.Option>
                <Select.Option key='other' value='other'>Other</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                { type: 'url', warningOnly: true },
              ]}
              hasFeedback
              label="Web site"
              name="webSite"
            >
              <Input placeholder="Your company's website (optional)" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: false },
                { type: 'string' },
              ]}
              hasFeedback
              label="How did you hear about us?"
              name="fromWhere"
            >
              <Input placeholder="e.g., Google, LinkedIn, Applovin" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: false },
                { type: 'string' },
              ]}
              hasFeedback
              label="Message"
              name="message"
            >
              <Input.TextArea allowClear placeholder="Tell us more about your needs or questions" />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
              <Button className='btn-primary-network' htmlType="submit">Send Message</Button>
            </Form.Item>
          </Form>
        ) : (
          <Result
            status="success"
            title={
              <div>
                <h2>Thank you for reaching out to us.</h2>
                <h2>We will get back to you very soon!</h2>
              </div>
            }
          />
        )}
      </div>

      <YcFooter/>
    </div>
  );
}
