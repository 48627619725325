import { Spin } from 'antd';
import { useMemo, useState } from 'react';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle } from '@/components';
import { NumberTools } from '@/tools';
import { reportingBiddingService } from '@/services';
import { usePageTitle } from '@/hooks/use-title';
import ReactGA from 'react-ga4';

import { ReportingFilter, Filters, HavingFilter, AddFilter } from '../../components';

import './reporting-bidding.page.scss';

interface Total {
  requests: number;
  responses: number;
  noBids: number;
  bidRate: number;
  wins: number;
  winRate: number;
  loss: number;
  lossRate: number;
  errors: number;
  errorRate: number;
  bills: number;
  impressions: number;
  displayRate: number;
  revenue: number;
  ecpm: number;
}

interface ResultLine {
  id: number;
  requests: number;
  responses: number;
  noBids: number;
  bidRate: number;
  wins: number;
  winRate: number;
  loss: number;
  lossRate: number;
  errors: number;
  errorRate: number;
  bills: number;
  impressions: number;
  displayRate: number;
  revenue: number;
  ecpm: number;

  date?: string;
  hourOfDay?: string;
  endpoint?: string;
  platform?: string;
  platformVersion?: string;
  source?: string;
  placementType?: string;
  countryCode?: string;
  lossCode?: string;
}

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

export function ReportingBiddingPage(props: {}) {
  usePageTitle('Reporting - Bidding');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/reporting/bidding',
    title: 'Reporting - Bidding'
  });

  const [filters, _setFilters] = useState<Filters | null>(null);
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);

  const onSetFilters = (filters) => {
    _setFilters(filters);
  };

  const onSearch = async (data: any) => {
    _setLoading(true);
    reportingBiddingService.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
      }
    });
  };

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({
      ...filters,
      [key]: filters![key].map((item) => {
        if (item.value === value || item.label === value) {
          item.checked = checked;
        }
        return item;
      }),
    });
  };

  const columDate: YcColumnType<ResultLine> = {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columHourOfDay: YcColumnType<ResultLine> = {
    title: 'Hour',
    key: 'hourOfDay',
    dataIndex: 'hourOfDay',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.hourOfDay}
        <AddFilter key="hours" item="hours" value={record.hourOfDay} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columEndpoint: YcColumnType<ResultLine> = {
    title: 'Endpoint',
    key: 'endpoint',
    dataIndex: 'endpoint',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.endpoint}
        <AddFilter key="endpoints" item="endpoints" value={record.endpoint} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columSource: YcColumnType<ResultLine> = {
    title: 'Source',
    key: 'source',
    dataIndex: 'source',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.source}
        <AddFilter key="sources" item="sources" value={record.source} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columPlacementType: YcColumnType<ResultLine> = {
    title: 'Placement Type',
    key: 'placementType',
    dataIndex: 'placementType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.placementType}
        <AddFilter key="placementTypes" item="placementTypes" value={record.placementType} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columCountry: YcColumnType<ResultLine> = {
    title: 'Country',
    key: 'countryCode',
    dataIndex: 'countryCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.countryCode}
        <AddFilter key="countries" item="countries" value={record.countryCode} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columPlatform: YcColumnType<ResultLine> = {
    title: 'Platform',
    key: 'platform',
    dataIndex: 'platform',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.platform}
        <AddFilter key="platforms" item="platforms" value={record.platform} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columPlatformVersion: YcColumnType<ResultLine> = {
    title: 'Platform Version',
    key: 'platformVersion',
    dataIndex: 'platformVersion',
    ycSort: 'numeric',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.platformVersion}
        <AddFilter key="platformVersions" item="platformVersions" value={record.platformVersion} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  const columLossCode: YcColumnType<ResultLine> = {
    title: 'Loss Code',
    key: 'lossCode',
    dataIndex: 'lossCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (
      <div>
        {record.lossCode}
        <AddFilter key="lossCodes" item="lossCodes" value={record.lossCode} filters={filters} onSetFilter={onSetFilter} />
      </div>
    ),
  };

  function Filter({ filterKey }: { filterKey: keyof ResultLine }) {
    return (
      <HavingFilter
        onFilter={(v, c) => _setTableData(results?.results.filter((v1) => c(v, v1[filterKey] as number) <= 0) || [])}
        onReset={() => _setTableData(results?.results || [])}
        filterKey={filterKey}
      />
    );
  }

  const columns: YcColumnType<ResultLine>[] = [
    {
      title: 'Requests',
      dataIndex: 'requests',
      key: 'requests',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.requests),
      filterDropdown: <Filter filterKey="requests" />,
    },
    {
      title: 'No Bids',
      dataIndex: 'noBids',
      key: 'noBids',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.noBids),
      filterDropdown: <Filter filterKey="noBids" />,
    },
    {
      title: 'Responses',
      dataIndex: 'responses',
      key: 'responses',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.responses),
      filterDropdown: <Filter filterKey="responses" />,
    },
    {
      title: 'Bid Rate',
      dataIndex: 'bidRate',
      key: 'bidRate',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.bidRate, 2),
      filterDropdown: <Filter filterKey="bidRate" />,
    },
    {
      title: 'Wins',
      dataIndex: 'wins',
      key: 'wins',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.wins),
      filterDropdown: <Filter filterKey="wins" />,
    },
    {
      title: 'Win Rate',
      dataIndex: 'winRate',
      key: 'winRate',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.winRate, 2),
      filterDropdown: <Filter filterKey="winRate" />,
    },
    {
      title: 'Loss',
      dataIndex: 'loss',
      key: 'loss',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.loss),
      filterDropdown: <Filter filterKey="loss" />,
    },
    {
      title: 'Loss Rate',
      dataIndex: 'lossRate',
      key: 'lossRate',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.lossRate, 2),
      filterDropdown: <Filter filterKey="lossRate" />,
    },
    {
      title: 'Errors',
      dataIndex: 'errors',
      key: 'errors',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.errors),
      filterDropdown: <Filter filterKey="errors" />,
    },
    {
      title: 'Error Rate',
      dataIndex: 'errorRate',
      key: 'errorRate',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.errorRate, 2),
      filterDropdown: <Filter filterKey="errorRate" />,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.impressions),
      filterDropdown: <Filter filterKey="impressions" />,
    },
    {
      title: 'Display Rate',
      dataIndex: 'displayRate',
      key: 'displayRate',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.displayRate, 2),
      filterDropdown: <Filter filterKey="displayRate" />,
    },
    {
      title: 'Billable Impressions',
      dataIndex: 'bills',
      key: 'bills',
      ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.bills),
      filterDropdown: <Filter filterKey="bills" />,
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      defaultSortOrder: 'descend',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.revenue, 3),
      filterDropdown: <Filter filterKey="revenue" />,
    },
    {
      title: 'eCPM',
      dataIndex: 'ecpm',
      key: 'ecpm',
      ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.ecpm, 3),
      filterDropdown: <Filter filterKey="ecpm" />,
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: YcColumnType<ResultLine>[] = [...columns];
    let bCols: YcColumnType<ResultLine>[] = [];
    if (results?.is.includes('country')) {
      bCols = [columCountry, ...bCols];
    }
    if (results?.is.includes('placementType')) {
      bCols = [columPlacementType, ...bCols];
    }
    if (results?.is.includes('source')) {
      bCols = [columSource, ...bCols];
    }
    if (results?.is.includes('platform')) {
      bCols = [columPlatform, ...bCols];
    }
    if (results?.is.includes('platformVersion')) {
      bCols = [columPlatformVersion, ...bCols];
    }
    if (results?.is.includes('lossCode')) {
      bCols = [columLossCode, ...bCols];
    }
    if (results?.is.includes('endpoint')) {
      bCols = [columEndpoint, ...bCols];
    }
    if (results?.is.includes('hourOfDay')) {
      bCols = [columHourOfDay, ...bCols];
    }
    if (results?.is.includes('granularity')) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [
        {
          title: 'Breakdown',
          dataIndex: 'breakdown',
          key: 'breakdown',
          ycCanNotHide: true,
          children: bCols,
        },
        ...cols,
      ];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results
    ? [
        { key: 'generique', value: undefined, colSpan: results!.is.length },
        { key: 'requests', value: NumberTools.largeNumber(results!.total.requests) },
        { key: 'noBids', value: NumberTools.largeNumber(results!.total.noBids) },
        { key: 'responses', value: NumberTools.largeNumber(results!.total.responses) },
        { key: 'bidRate', value: NumberTools.roundPercentage(results!.total.bidRate, 2) },
        { key: 'wins', value: NumberTools.largeNumber(results!.total.wins) },
        { key: 'winRate', value: NumberTools.roundPercentage(results!.total.winRate, 2) },
        { key: 'loss', value: NumberTools.largeNumber(results!.total.loss) },
        { key: 'lossRate', value: NumberTools.roundPercentage(results!.total.lossRate, 2) },
        { key: 'errors', value: NumberTools.largeNumber(results!.total.errors) },
        { key: 'errorRate', value: NumberTools.roundPercentage(results!.total.errorRate, 2) },
        { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
        { key: 'displayRate', value: NumberTools.roundPercentage(results!.total.displayRate, 2) },
        { key: 'bills', value: NumberTools.largeNumber(results!.total.bills) },
        { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
        { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
      ]
    : undefined;

  return (
    <div id="reporting-bidding">
      <YcTitle label="Reporting - Bidding"></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter service={reportingBiddingService} onSearch={onSearch} onSetFilters={onSetFilters} filters={filters} type="bidding"></ReportingFilter>
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue']} />
            </div>
            <YcTable bordered size="small" dataSource={tableData} columns={columnsMemo} ycSummarys={summarys} ycTableKey="reporting-bidding" rowKey="id" />
          </div>
        )}
      </Spin>
    </div>
  );
}
