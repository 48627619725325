import { Spin } from 'antd';
import { useMemo, useState } from 'react';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle } from "@/components";
import { NumberTools } from "@/tools";
import { reportingPublishingService } from "@/services";
import { usePageTitle } from '@/hooks/use-title';
import ReactGA from 'react-ga4';

import { ReportingFilter, Filters, HavingFilter, AddFilter } from "../../components";

import './reporting-publishing.page.scss';


interface Total {
  requests: number;
  responses: number;
  fillRate: number;
  wins: number;
  winRate: number;
  impressions: number;
  revenue: number;
  ecpm: number;
}

interface ResultLine {
  id: number;
  requests: number;
  responses: number;
  fillRate: number;
  wins: number;
  winRate: number;
  impressions: number;
  revenue: number;
  ecpm: number;

  date?: string;
  hourOfDay?: string;
  publisher?: string;
  platform?: string;
  platformVersion?: string;
  sdkVersion?: string;
  source?: string;
  placement?: string;
  placementType?: string;
  countryCode?: string;
  isMediation?: boolean;
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

export function ReportingPublishingPage(props: {}) {
  usePageTitle('Reporting - Publishing');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/reporting/publishing',
    title: 'Reporting - Publishing'
  });

  const [filters, _setFilters] = useState<Filters | null>(null);
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);

  const onSetFilters = (filters) => {
    _setFilters(filters);
  }

  const onSearch = async (data: any) => {
    _setLoading(true);
    reportingPublishingService.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
      }
    });
  }

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({ ...filters, [key]: filters![key].map(item => { if (item.value === value || item.label === value) { item.checked = checked; } return item; }) });
  }

  const columDate: YcColumnType<ResultLine> = {
    title: 'Date', key: 'date', dataIndex: 'date',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columHourOfDay: YcColumnType<ResultLine> = {
    title: 'Hour', key: 'hourOfDay', dataIndex: 'hourOfDay',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.hourOfDay}
      <AddFilter key="hours" item="hours" value={record.hourOfDay} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columSource: YcColumnType<ResultLine> = {
    title: 'Source', key: 'source', dataIndex: 'source',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.source}
      <AddFilter key="sources" item="sources" value={record.source} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacement: YcColumnType<ResultLine> = {
    title: 'Placement', key: 'placement', dataIndex: 'placement',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placement}
      <AddFilter key="placements" item="placements" value={record.placement} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacementType: YcColumnType<ResultLine> = {
    title: 'Placement Type', key: 'placementType', dataIndex: 'placementType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placementType}
      <AddFilter key="placementTypes" item="placementTypes" value={record.placementType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCountry: YcColumnType<ResultLine> = {
    title: 'Country', key: 'countryCode', dataIndex: 'countryCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.countryCode}
      <AddFilter key="countries" item="countries" value={record.countryCode} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatform: YcColumnType<ResultLine> = {
    title: 'Platform', key: 'platform', dataIndex: 'platform',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platform}
      <AddFilter key="platforms" item="platforms" value={record.platform} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatformVersion: YcColumnType<ResultLine> = {
    title: 'Platform Version', key: 'platformVersion', dataIndex: 'platformVersion',
    ycSort: 'numeric',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platformVersion}
      <AddFilter key="platformVersions" item="platformVersions" value={record.platformVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columSdkVersion: YcColumnType<ResultLine> = {
    title: 'SDK Version', key: 'sdkVersion', dataIndex: 'sdkVersion',
    ycSort: 'numeric',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.sdkVersion}
      <AddFilter key="sdkVersions" item="sdkVersions" value={record.sdkVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  function Filter({ filterKey }: { filterKey: keyof ResultLine }) {
    return <HavingFilter
      onFilter={(v, c) => _setTableData(results?.results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
      onReset={() => _setTableData(results?.results || [])}
      filterKey={filterKey}
    />;
  }

  const columns: YcColumnType<ResultLine>[] = [
    {
      title: 'Requests', dataIndex: 'requests', key: 'requests', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.requests),
      filterDropdown: <Filter filterKey='requests' />
    },
    {
      title: 'Responses', dataIndex: 'responses', key: 'responses', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.responses),
      filterDropdown: <Filter filterKey='responses' />
    },
    {
      title: 'Fill Rate', dataIndex: 'fillRate', key: 'fillRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.fillRate, 2),
      filterDropdown: <Filter filterKey='fillRate' />
    },
    {
      title: 'Wins', dataIndex: 'wins', key: 'wins', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.wins),
      filterDropdown: <Filter filterKey='wins' />
    },
    {
      title: 'Win Rate', dataIndex: 'winRate', key: 'winRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.winRate, 2),
      filterDropdown: <Filter filterKey='winRate' />
    },
    {
      title: 'Impressions', dataIndex: 'impressions', key: 'impressions', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.impressions),
      filterDropdown: <Filter filterKey='impressions' />
    },
    {
      title: 'Revenue', dataIndex: 'revenue', key: 'revenue', defaultSortOrder: 'descend', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.revenue, 3),
      filterDropdown: <Filter filterKey='revenue' />
    },
    {
      title: 'eCPM', dataIndex: 'ecpm', key: 'ecpm', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.ecpm, 3),
      filterDropdown: <Filter filterKey='ecpm' />
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: YcColumnType<ResultLine>[] = [...columns];
    let bCols: YcColumnType<ResultLine>[] = [];
    if (results?.is.includes("country")) {
      bCols = [columCountry, ...bCols];
    }
    if (results?.is.includes("placement")) {
      bCols = [columPlacement, ...bCols];
    }
    if (results?.is.includes("placementType")) {
      bCols = [columPlacementType, ...bCols];
    }
    if (results?.is.includes("source")) {
      bCols = [columSource, ...bCols];
    }
    if (results?.is.includes("platform")) {
      bCols = [columPlatform, ...bCols];
    }
    if (results?.is.includes("platformVersion")) {
      bCols = [columPlatformVersion, ...bCols];
    }
    if (results?.is.includes("sdkVersion")) {
      bCols = [columSdkVersion, ...bCols];
    }
    if (results?.is.includes("hourOfDay")) {
      bCols = [columHourOfDay, ...bCols];
    }
    if (results?.is.includes("granularity")) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        ycCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    { key: 'requests', value: NumberTools.largeNumber(results!.total.requests) },
    { key: 'responses', value: NumberTools.largeNumber(results!.total.responses) },
    { key: 'fillRate', value: NumberTools.roundPercentage(results!.total.fillRate, 2) },
    { key: 'wins', value: NumberTools.largeNumber(results!.total.wins) },
    { key: 'winRate', value: NumberTools.roundPercentage(results!.total.winRate, 2) },
    { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
    { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
    { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },

  ] : undefined;

  return (
    <div id='reporting-publishing'>
      <YcTitle label="Reporting - Publishing" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter service={reportingPublishingService} onSearch={onSearch} onSetFilters={onSetFilters} filters={filters} type="publishing"></ReportingFilter>
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue']} />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-publishing"
              rowKey="id"
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
