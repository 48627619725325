import { useEffect } from "react";

export function setPageTitle(title: string) {
  document.title = title;
}

export function usePageTitle(title: string) {
  useEffect(() => {
    if (window.location.pathname.startsWith("/superadmin/")) {
      setPageTitle("[SA] " + title + " - Yso Network");
    } else {
      setPageTitle(title + " - Yso Network");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);
}