import { Table, Input, Select, Button, Spin } from 'antd';
import { useMemo } from 'react';

import './ads.page.scss';
import { YcTitle } from '../../../../components';
import { NumberTools } from "../../../../tools";
import { PlusCircleOutlined } from '@ant-design/icons';
import { AdminAd, useAds } from './useAds';
import { usePageTitle } from '@/hooks/use-title';

function Summary({ ads }: { ads: AdminAd[] }) {
  const total = useMemo(() => {
    let impressions = 0;
    let clicks = 0;
    let installs = 0;

    ads.forEach((a) => {
      impressions += a.impressions || 0;
      clicks += a.clicks || 0;
      installs += a.installs || 0;
    });

    let ir = ((installs / impressions) * 100) || 0;
    let ctr = ((clicks / impressions) * 100) || 0;
    return {
      impressions,
      clicks,
      installs,
      ir,
      ctr,
    }
  }, [ads]);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={9}>Total</Table.Summary.Cell>
      <Table.Summary.Cell index={9}>{total.impressions}</Table.Summary.Cell>
      <Table.Summary.Cell index={10}>{total.clicks}</Table.Summary.Cell>
      <Table.Summary.Cell index={11}>{total.installs}</Table.Summary.Cell>
      <Table.Summary.Cell index={12} colSpan={2}>{NumberTools.roundPercentage(total.ctr, 3)}</Table.Summary.Cell>
      <Table.Summary.Cell index={13}>{NumberTools.roundPercentage(total.ir, 3)}</Table.Summary.Cell>
    </Table.Summary.Row>
  );
}

function ParamRow({ name, children }) {
  return (
    <div className='row'>
      <div className='col-1 d-flex justify-content-end field-label'>{name}</div>
      <div className='col-11'>
        {children}
      </div>
    </div>
  );
}

export function SAAdsPage() {
  usePageTitle('Ads');
  const {
    state,
    filters,
    onLoadAds,
    onSelectCountry,
    onChangeCity,
    onSelectBundle,
    onSelectPlacementType,
    onSelectOs,
    onSelectOsVersion,
    onSelectHasIfa,
    onSelectAdPhase,
    onSelectBidAbTest,
    onSelectIsTestDevice,
    onChangeIfa,
    onAddDeviceApp,
    onRemoveDeviceApp,
    onAdEvent,
    onResetDeviceAd,
  } = useAds();

  return (
    <div id='admin-ads'>
      <Spin spinning={state.loading}>
        <YcTitle label="Ads" ></YcTitle>
        <ParamRow name='Country'>
          <Select onSelect={onSelectCountry} value={state.country} showSearch placeholder="Select country" className='mb-1 w-100'>
            {filters.countries.map((data: any) => (
              <Select.Option key={data[0]} value={data[0]}>{data[0]} - {data[1]}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='City'>
          <Input placeholder="City" type="text" value={state.city} onChange={onChangeCity} className='mb-1 w-100' />
        </ParamRow>
        <ParamRow name='Bundle'>
          <Select onSelect={onSelectBundle} value={state.bundle} showSearch placeholder="Select bundle" className='mb-1 w-100'>
            {state.applications.map((app: string) => (
              <Select.Option key={app} value={app}>{app}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Ad type'>
          <Select onSelect={onSelectPlacementType} value={state.placementType} showSearch placeholder="Select placement type" className='mb-1 w-100'>
            {filters.placementTypes.map((placementType: any) => (
              <Select.Option key={placementType} value={placementType}>{placementType}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='OS'>
          <Select onSelect={onSelectOs} value={state.os} showSearch placeholder="Select os" className='mb-1 w-100'>
            {filters.os.map((os: any) => (
              <Select.Option key={os} value={os}>{os}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='OS version'>
          <Select onSelect={onSelectOsVersion} value={state.osVersion} showSearch placeholder="Select os version" className='mb-1 w-100'>
            {filters.osVersions.map((osVersion: any) => (
              <Select.Option key={osVersion} value={osVersion}>{osVersion}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Has ifa'>
          <Select onSelect={onSelectHasIfa} value={state.hasIfa} showSearch placeholder="Select has ifa" className='mb-1 w-100'>
            {[true, false].map((hasIfa: any) => (
              <Select.Option key={hasIfa} value={hasIfa}>{hasIfa ? 'true' : 'false'}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Ad phase'>
          <Select onSelect={onSelectAdPhase} value={state.adPhase} showSearch placeholder="Select ad phase" className='mb-1 w-100'>
            {filters.adPhases.map((adPhase: any) => (
              <Select.Option key={adPhase} value={adPhase}>{adPhase}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Bid ab test'>
          <Select onSelect={onSelectBidAbTest} value={state.bidAbTest} showSearch placeholder="Select bid ab test" className='mb-1 w-100'>
            {filters.bidAbTests.map((bidAbTest: any) => (
              <Select.Option key={bidAbTest} value={bidAbTest}>{bidAbTest}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Is test device'>
          <Select onSelect={onSelectIsTestDevice} value={state.isTestDevice} showSearch placeholder="Select is test device" className='mb-1 w-100'>
            {[true, false].map((isTestDevice: any) => (
              <Select.Option key={isTestDevice} value={isTestDevice}>{isTestDevice ? 'true' : 'false'}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Device apps'>
          <Select onSelect={onAddDeviceApp} value={null} showSearch placeholder="Select bundle" className='mb-1 w-100'>
            {state.applications.map((app: any) => (
              <Select.Option key={app} value={app}>{app}</Select.Option>
            ))}
          </Select>
        </ParamRow>
        <ParamRow name='Ifa'>
          <Input placeholder="ifa" type="text" value={state.ifa} onChange={onChangeIfa} style={{ width: '350px' }} className='mb-2 w-100' />
        </ParamRow>
        <div>
          {state.deviceApps.map((deviceApp: any) => (
            <Button style={{ fontSize: '12px', color: 'red' }} type="text" onClick={() => onRemoveDeviceApp(deviceApp)} className='mb-2'>{deviceApp}</Button>
          ))}
        </div>

        <Button type="primary" onClick={onLoadAds} className='mb-2 me-2'>Load Ads</Button>
        <Button type="default" onClick={onResetDeviceAd} className='mb-2'>Reset Device Ads</Button>
        <Table bordered dataSource={state.ads} size="small" pagination={{ pageSize: 100 }} summary={() => <Summary ads={state.ads} />} columns={[
          { title: 'Id', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id - b.id },
          { title: 'Key', dataIndex: 'key', key: 'key', sorter: (a, b) => a.key.localeCompare(b.key) },
          { title: 'Ad Phase', dataIndex: 'adPhase', key: 'adPhase', sorter: (a, b) => a.adPhase.localeCompare(b.adPhase) },
          {
            title: 'Percent', dataIndex: 'percent', key: 'percent',
            sorter: (a, b) => a.percent - b.percent, defaultSortOrder: 'descend',
            render: (text: string, record) => `${NumberTools.roundPercentage(record.percent, 2)} ± ${NumberTools.roundPercentage(record.standardDeviationPercent, 2)}`,
          },
          {
            title: 'Ad Price', dataIndex: 'adPrice', key: 'adPrice',
            sorter: (a, b) => a.adPrice - b.adPrice,
            render: (text: string, record) => NumberTools.roundPrice(record.adPrice, 3),
          },
          {
            title: 'Ad IR', dataIndex: 'adIr', key: 'adIr',
            sorter: (a, b) => a.adIr - b.adIr,
            render: (text: string, record) => NumberTools.roundPercentage(record.adIr, 3),
          },
          {
            title: 'Bid Price', dataIndex: 'price', key: 'price',
            sorter: (a, b) => a.price - b.price,
            render: (text: string, record) => NumberTools.roundPrice(record.price, 3),
          },
          {
            title: 'Bid IR', dataIndex: 'bidIr', key: 'bidIr',
            sorter: (a, b) => a.bidIr - b.bidIr,
            render: (text: string, record) => NumberTools.roundPercentage(record.bidIr, 3),
          },
          {
            title: 'CPI', dataIndex: 'cpi', key: 'cpi',
            sorter: (a, b) => a.cpi - b.cpi,
            render: (text: string, record) => NumberTools.roundPrice(record.cpi, 3),
          },
          {
            title: 'Global',
            children: [
              {
                title: 'Impressions',
                dataIndex: 'impressions',
                key: 'impressions',
                sorter: (a, b) => a.impressions - b.impressions,
                render: (text: string, record) => (<>
                  {record.impressions} <Button onClick={
                    () => onAdEvent(record.id, 'impressions', state.deviceAds)} size="small" icon={<PlusCircleOutlined />
                    } /> <span style={{ color: 'green' }}>{
                      state.deviceAds[record.id]?.impressions ? '(' + (state.deviceAds[record.id]?.impressions || 0) + ')' : ''
                    }</span>
                </>),
              },
              {
                title: 'Clicks',
                dataIndex: 'clicks',
                key: 'clicks',
                sorter: (a, b) => a.clicks - b.clicks,
                render: (text: string, record) => (<>
                  {record.clicks} <Button onClick={
                    () => onAdEvent(record.id, 'clicks', state.deviceAds)} size="small" icon={<PlusCircleOutlined />
                    } /> <span style={{ color: 'green' }}>{
                      state.deviceAds[record.id]?.clicks ? '(' + (state.deviceAds[record.id]?.clicks || 0) + ')' : ''
                    }</span>
                </>),
              },
              {
                title: 'Installs',
                dataIndex: 'installs',
                key: 'installs',
                sorter: (a, b) => a.installs - b.installs,
                render: (text: string, record) => (<>
                  {record.installs} <Button onClick={
                    () => onAdEvent(record.id, 'installs', state.deviceAds)} size="small" icon={<PlusCircleOutlined />
                    } /> <span style={{ color: 'green' }}>{
                      state.deviceAds[record.id]?.installs ? '(' + (state.deviceAds[record.id]?.installs || 0) + ')' : ''
                    }</span>
                </>),
              },
              {
                title: 'CPM', dataIndex: 'gCpm', key: 'gCpm',
                sorter: (a, b) => a.gCpm - b.gCpm,
                render: (text: string, record) => NumberTools.roundPrice(record.gCpm, 2),
              },
              {
                title: 'CTR', dataIndex: 'gCtr', key: 'gCtr',
                sorter: (a, b) => a.gCtr - b.gCtr,
                render: (text: string, record) => NumberTools.roundPercentage(record.gCtr, 2),
              },
              {
                title: 'IR', dataIndex: 'gIr', key: 'gIr',
                sorter: (a, b) => a.gIr - b.gIr,
                render: (text: string, record) => NumberTools.roundPercentage(record.gIr, 3),
              },
            ]
          },
        ]} rowKey="id" />
      </Spin>
    </div>
  );
}
