import './asset-playable.scss';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface YcAssetVideoProps {
  url: string;
  isBigPreview?: boolean
}

export function YcAssetPlayable(props: YcAssetVideoProps) {
  const [fullScreen, setFullScreen] = React.useState(false);

  return (
    <div className={`c-playable ${props.isBigPreview ? 'big-preview' : ''}`}>
      <iframe title="Playable" src={props.url} className={`iframe ${props.isBigPreview ? 'big-preview' : ''} ${fullScreen ? 'is-fullscreen' : ''}`}></iframe>
      {!fullScreen && <Button shape="circle" size="small" className="fullscreen-button" icon={<FullscreenOutlined />} onClick={() => setFullScreen(true)} />}
      {fullScreen && <Button shape="circle" size="large" className="fullscreen-button is-fullscreen" icon={<FullscreenExitOutlined />} onClick={() => setFullScreen(false)} />}
    </div>
  );
}
