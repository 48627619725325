import { Form, Input, Button, Spin } from 'antd';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { authsService } from '../../../../services';

// styles
import './reset-password.page.scss';
import { usePageTitle } from '@/hooks/use-title';
import { YcTitle } from '../../../../components';

export function ResetPasswordPage() {
  usePageTitle('Reset Password');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/reset-password',
    title: 'Reset Password'
  });

  const [loading, _setLoading] = useState(false);

  const uHistory = useHistory();
  const uLocation = useLocation();

  const onFinish = async (data: { email: string }) => {
    _setLoading(true);
    const query = new URLSearchParams(uLocation.search);
    if (await authsService.resetPassword({
      ...data,
      token: query.get('token'),
    })) {
      uHistory.push('/login');
    }
    _setLoading(false);
  }

  return (
    <div className='root-reset-password'>
      <Spin spinning={loading}>
        <YcTitle label="Reset password"></YcTitle>
        <div className='block-auth'>
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input placeholder="New password" type="password" />
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button type="primary" htmlType="submit">Reset Password</Button>
              <Link className='pl-3' to="/login">Login</Link>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
