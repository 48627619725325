export class MControlPanel {
  ynCustomerUA: { date: string; account: string; spend: number; }[] = [];
  ynCustomerMonet: { date: string; account: string; revenue: number; }[] = [];
  maxYsoSov: { date: string; withBanner: number; withoutBanner: number; }[] = [];
  maxYsoRevenue: { date: string; revenue: number; totalRevenue: number; totalRevenueNoBanner: number; }[] = [];
  ynYsoRevenue:  { date: string; ua: number; monet: number; profit: number; roi: number; }[] = [];
  ynRoi: { date: string; ua: number; monet: number; profit: number; roi: number; roiControl: number; }[] = [];
  ynCampaignRoas: { date: string; roasD0: number; roasD7: number; }[] = [];
  ynYsoVsCustomer: { date: string; sCustomer: number; sYsoCorp: number; sTotal: number; rCustomer: number; rYsoCorp: number; rTotal: number; }[] = [];
}
