import { usePageTitle } from '@/hooks/use-title';
import { YcFooter, YcHeaderTitle, YcDocReader } from '@/components';

import './legal-mentions.page.scss';
import ReactGA from 'react-ga4';

export function LegalMentions() {
  usePageTitle('legal-mentions');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/legal-mentions',
    title: 'legal-mentions'
  });

  const headerTitle = <h1>Legal Mentions</h1>

  return (
    <div id="root-legal-mentions">
      <YcHeaderTitle title={headerTitle} />
      <div className='container d-flex justify-content-center mt-5'>
        <YcDocReader file='/ysonetworklegalmentions.html'/>
      </div>
      <YcFooter/>
    </div>
  );
}


