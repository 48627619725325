import React, { useEffect, useState } from 'react';
import { authsService } from '@/services';
import { useHistory, useLocation } from 'react-router-dom';
import { Permissions, uniques } from '@/modeles';

class UserAuthAccount {
  id: number = 0;
  name: string = "";
  isAdvertiser: boolean = false;
  isPublisher: boolean = false;
  isBidder: boolean = false;
  Permissions: { path: string; }[] = [];
}

export class UserAuth {
  id: number = 0;
  accountId: number = 0;
  email: string = "";
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  Permissions: { path: string; }[] = [];
  Account: UserAuthAccount = new UserAuthAccount();
}

export interface AuthsContextType {
  user: UserAuth | null;
  originalUser: UserAuth | null;
  isLogin: () => boolean;
  isAdmin: () => boolean;
  isReady: () => boolean;
  isSuperAdmin: () => boolean;
  signup: (data: any) => Promise<void>;
  login: (data: any) => Promise<void>;
  loginAs: (userId: number) => Promise<void>;
  logoutAs: () => Promise<void>;
  autologin: () => Promise<void>;
  logout: () => Promise<void>;
  permissions: Permissions | null;
}

const AuthsContext = React.createContext<AuthsContextType | null>(null);

function getPermissions(user: UserAuth) {
  const p = new Permissions();
  p.parseFromPaths(uniques(user.Permissions.map(({ path }) => path), user.Account.Permissions.map(({ path }) => path)))
  return p;
}

export function AuthsContextProvider({ children }: { children: React.ReactNode }) {
  const [user, _setUser] = useState<UserAuth | null>(null);
  const [originalUser, _setOriginalUser] = useState<UserAuth | null>(null);
  const [permissions, _setPermissions] = useState<Permissions | null>(null);
  const [ready, _setReady] = useState(false);

  const uHistory = useHistory();
  const uLocation = useLocation();

  const _setUserAndPermissions = (user: UserAuth | null) => {
    _setUser(user);
    if (user) {
      _setPermissions(getPermissions(user));
    } else {
      _setPermissions(null);
    }
  }

  const _setOriginalUserAndPermissions = (user: UserAuth | null, originalUser: UserAuth | null) => {
    _setOriginalUser(originalUser);
    _setUserAndPermissions(user);
  }

  const value: AuthsContextType = {
    user,
    originalUser,
    isLogin() {
      return !!user;
    },
    isReady() {
      return ready;
    },
    isAdmin() {
      return !!user && user!.isAdmin;
    },
    isSuperAdmin() {
      return !!user && user!.isSuperAdmin;
    },
    async signup(data: any) {
      const signupUser = await authsService.signup(data);
      if (signupUser != null) {
        _setUserAndPermissions(signupUser);
        uHistory.push("/");
      }
    },
    async login(data: any) {
      const loginUser = await authsService.login(data);
      if (loginUser != null) {
        _setUserAndPermissions(loginUser);
        uHistory.push("/");
      }
    },
    async loginAs(userId: number) {
      const impersonatedUser = await authsService.loginAs(userId);
      if (impersonatedUser != null) {
        _setOriginalUserAndPermissions(impersonatedUser, user);
        uHistory.push("/");
      }
    },
    async logoutAs() {
      authsService.logoutAs();
      _setOriginalUserAndPermissions(originalUser, null);
      uHistory.push("/");
    },
    async autologin() {
      const autologinUser = await authsService.autologin();
      if (autologinUser) {
        _setUserAndPermissions(autologinUser);
        if (uLocation.pathname === "/login") {
          uHistory.push("/");
        }
      } else {
        if (![
          "/",
          "/login",
          "/forgot-password",
          "/sign-up",
          "/reset-password",
          "/contact-us",
          "/acquisition",
          "/monetization",
          "/ad-exchange",
          "/ads-formats",
          "/about",
          "/policies-advertisers",
          "/policies-publishers",
          "/terms",
          "/privacy",
          "/legal-mentions",
          "/data-processing-agreement",
        ].includes(uLocation.pathname)) {
          uHistory.push("/login");
        }
      }
      _setReady(true);
    },
    async logout() {
      await authsService.logout();
      _setUserAndPermissions(null);
      _setOriginalUserAndPermissions(null, null);
    },
    permissions,
  };

  useEffect(() => {
    value.autologin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthsContext.Provider value={value}>
      {children}
    </AuthsContext.Provider>
  );
}

export function useAuths() {
  const context = React.useContext(AuthsContext);
  if (!context) throw new Error('No AuthsContext provider found!');
  return context;
}
