export class FilterTool {

  public static BOOL_filters(ok: string, ko: string, empty: string = "") {
    const list: {text: string, value: number | string | boolean}[] = [{ text: ok, value: true }, { text: ko, value: false }];
    if (empty !== "") {
      list.push({ text: empty, value: '' });
    }
    return list;
  }

  public static BOOL_onFilter(field: string) {
    return (value, record) => (value === '' && record[field] === null) || record[field] === value;
  }

  public static STRING_filters(values: string[]) {
    const list: {text: string, value: string }[] = values.map(v => ({ text: v, value: v }))
    return list;
  }

  public static STRING_onFilter(getField: (record) => string) {
    return (value, record) => (value === '' && getField(record) === null) || getField(record) === value;
  }

}