import { Spin } from 'antd';
import { useMemo, useState } from 'react';
import { useAuths } from '@/contexts/auths.context';

import { YcTitle, ReportLineGraph } from "@/components";
import { YcTable, YcColumnType } from "@/components/table";
import { reportingAdvertisingService_SA } from "@/services";
import { NumberTools } from "@/tools";
import { usePageTitle } from '@/hooks/use-title';

import { ReportingFilterSuperAdmin, FiltersSuperAdmin, HavingFilter, AddFilter } from '../../../components';

import './reporting-advertising.page.scss';

interface Total {
  impressions: number;
  clicks: number;
  installs: number;
  spend: number;
  realSpend: number;
  devices: number;
  marge: number;
  roi: number;
  perf: number;
  ctr: number;
  ir: number;
  ecpi: number;
  realEcpi: number;
  ecpm: number;
  realEcpm: number;
  bidIr: number;
  realBidIr: number;
  avgBidIr: number;
  avgRealBidIr: number;
  brImpressions: number;
  brCtr: number;
  brIr: number;
  brEcpm: number;
  brRealEcpm: number;
  iapRevenueD0: number;
  iapRevenueD1: number;
  iapRevenueD14: number;
  iapRevenueD180: number;
  iapRevenueD2: number;
  iapRevenueD3: number;
  iapRevenueD30: number;
  iapRevenueD365: number;
  iapRevenueD45: number;
  iapRevenueD60: number;
  iapRevenueD7: number;
  iapRevenueD75: number;
  iapRevenueD90: number;
  iapRevenueLifetime: number;
  revenueD0: number;
  revenueD1: number;
  revenueD14: number;
  revenueD180: number;
  revenueD2: number;
  revenueD3: number;
  revenueD30: number;
  revenueD365: number;
  revenueD45: number;
  revenueD60: number;
  revenueD7: number;
  revenueD75: number;
  revenueD90: number;
  revenueLifetime: number;
}

interface ResultLine {
  id: number;
  impressions: number;
  clicks: number;
  installs: number;
  spend: number;
  realSpend: number;
  devices: number;
  marge: number;
  roi: number;
  perf: number;
  ctr: number;
  ir: number;
  ecpi: number;
  realEcpi: number;
  ecpm: number;
  realEcpm: number;
  bidIr: number;
  realBidIr: number;
  avgBidIr: number;
  avgRealBidIr: number;
  brImpressions: number;
  brCtr: number;
  brIr: number;
  brEcpm: number;
  brRealEcpm: number;
  iapRevenueD0: number;
  iapRevenueD1: number;
  iapRevenueD14: number;
  iapRevenueD180: number;
  iapRevenueD2: number;
  iapRevenueD3: number;
  iapRevenueD30: number;
  iapRevenueD365: number;
  iapRevenueD45: number;
  iapRevenueD60: number;
  iapRevenueD7: number;
  iapRevenueD75: number;
  iapRevenueD90: number;
  iapRevenueLifetime: number;
  revenueD0: number;
  revenueD1: number;
  revenueD14: number;
  revenueD180: number;
  revenueD2: number;
  revenueD3: number;
  revenueD30: number;
  revenueD365: number;
  revenueD45: number;
  revenueD60: number;
  revenueD7: number;
  revenueD75: number;
  revenueD90: number;
  revenueLifetime: number;

  date?: string;
  hourOfDay?: string;
  advertiser?: string;
  publisher?: string;
  platform?: string;
  applicationKey?: string;
  campaignKey?: string;
  countryCode?: string;
  source?: string;
  placementType?: string;
  placementPhase?: string;
  adType?: string;
  adPhase?: string;
  creativeType?: string;
  bidAbTest?: string;
  abTest?: string;
  abTestVersion?: number;
  deviceNbApp?: number;
  deviceNbAttempt?: number;
  rangeBidIr?: number;
  adName?: string;
  hasIfa?: boolean;
}

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

export function SAReportingAdvertisingPage(props: {}) {
  usePageTitle('Reporting - Advertising');
  const [filters, _setFilters] = useState<FiltersSuperAdmin | null>(null);
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);

  const uAuths = useAuths();

  const onSetFilters = (filters) => {
    _setFilters(filters);
  }

  const onSearch = async (data: any) => {
    _setLoading(true);
    const metricsMap = {
      avgBidIr: 'bidIr',
      avgRealBidIr: 'realBidIr',
    }
    data.metrics = ['clicks', 'impressions', 'installs', 'spend', 'avgBidIr', 'avgRealBidIr', 'devices', 'realSpend', 'r0', 'r1', 'r2', 'r3', 'r7', 'r14', 'r30', 'r45', 'r60', 'r75', 'r90', 'r180', 'r365']
      .filter((m) => !hiddenFields.includes(m))
      .map((m) => metricsMap[m] || m)
      .join();
    reportingAdvertisingService_SA.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        res.total.marge = res.total.spend - res.total.realSpend;
        res.total.roi = res.total.realSpend ? (res.total.spend / res.total.realSpend * 100) - 100 : 0;
        const perfTotal = res.total.realSpend ? res.results.reduce((acc, cur) => acc + ((cur.spend - cur.realSpend) * (cur.realSpend / res.total.realSpend)), 0) : 0;
        res.total.perf = res.total.realSpend ? (perfTotal < 0 ? -100 : 100) : 0;
        res.results = res.results.map((e: ResultLine) => ({
          ...e,
          marge: e.spend - e.realSpend,
          roi: e.realSpend ? (e.spend / e.realSpend * 100) - 100 : 0,
          perf: res.total.realSpend && perfTotal ? (((((e.spend - e.realSpend) * (e.realSpend / res.total.realSpend)) * (perfTotal < 0 ? -1 : 1)) / perfTotal) * 100) : 0,
        }));
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
      }
    });
  }

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({ ...filters, [key]: filters![key].map(item => { if (item.value === value || item.label === value) { item.checked = checked; } return item; }) });
  }

  const columDate: YcColumnType<ResultLine> = {
    title: 'Date', key: 'date', dataIndex: 'date',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columHourOfDay: YcColumnType<ResultLine> = {
    title: 'Hour', key: 'hourOfDay', dataIndex: 'hourOfDay',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.hourOfDay}
      <AddFilter key="hours" item="hours" value={record.hourOfDay} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAdvertiser: YcColumnType<ResultLine> = {
    title: 'Advertiser', key: 'advertiser', dataIndex: 'advertiser',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.advertiser}
      <AddFilter key="advertisers" item="advertisers" value={record.advertiser} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPublisher: YcColumnType<ResultLine> = {
    title: 'Publisher', key: 'publisher', dataIndex: 'publisher',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.publisher}
      <AddFilter key="publishers" item="publishers" value={record.publisher} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columApplication: YcColumnType<ResultLine> = {
    title: 'Application', key: 'applicationKey', dataIndex: 'applicationKey',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.applicationKey}
      <AddFilter key="applications" item="applications" value={record.applicationKey} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAdName: YcColumnType<ResultLine> = {
    title: 'Ad Name', key: 'adName', dataIndex: 'adName',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columSource: YcColumnType<ResultLine> = {
    title: 'Source', key: 'source', dataIndex: 'source',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.source}
      <AddFilter key="sources" item="sources" value={record.source} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacementType: YcColumnType<ResultLine> = {
    title: 'Placement Type', key: 'placementType', dataIndex: 'placementType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placementType}
      <AddFilter key="placementTypes" item="placementTypes" value={record.placementType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacementPhase: YcColumnType<ResultLine> = {
    title: 'Placement Phase', key: 'placementPhase', dataIndex: 'placementPhase',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placementPhase}
      <AddFilter key="placementPhases" item="placementPhases" value={record.placementPhase} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAdType: YcColumnType<ResultLine> = {
    title: 'Ad Type', key: 'adType', dataIndex: 'adType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.adType}
      <AddFilter key="adTypes" item="adTypes" value={record.adType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAdPhase: YcColumnType<ResultLine> = {
    title: 'Ad Phase', key: 'adPhase', dataIndex: 'adPhase',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.adPhase}
      <AddFilter key="adPhases" item="adPhases" value={record.adPhase} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCreativeType: YcColumnType<ResultLine> = {
    title: 'Creative Type', key: 'creativeType', dataIndex: 'creativeType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.creativeType}
      <AddFilter key="creativeTypes" item="creativeTypes" value={record.creativeType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columBidAbTest: YcColumnType<ResultLine> = {
    title: 'Bid Ab Test', key: 'bidAbTest', dataIndex: 'bidAbTest',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.bidAbTest}
      <AddFilter key="bidAbTests" item="bidAbTests" value={record.bidAbTest} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAbTest: YcColumnType<ResultLine> = {
    title: 'Ab Test', key: 'abTest', dataIndex: 'abTest',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.abTest}
      <AddFilter key="abTests" item="abTests" value={record.abTest} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAbTestVersion: YcColumnType<ResultLine> = {
    title: 'Ab Test Version', key: 'abTestVersion', dataIndex: 'abTestVersion',
    ycSort: 'number',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.abTestVersion}
      <AddFilter key="abTestVersions" item="abTestVersions" value={record.abTestVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCampaign: YcColumnType<ResultLine> = {
    title: 'Campaign', key: 'campaignKey', dataIndex: 'campaignKey',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.campaignKey}
      <AddFilter key="campaigns" item="campaigns" value={record.campaignKey} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCountry: YcColumnType<ResultLine> = {
    title: 'Country', key: 'countryCode', dataIndex: 'countryCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.countryCode}
      <AddFilter key="countries" item="countries" value={record.countryCode} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatform: YcColumnType<ResultLine> = {
    title: 'Platform', key: 'platform', dataIndex: 'platform',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platform}
      <AddFilter key="platforms" item="platforms" value={record.platform} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columDeviceNbApp: YcColumnType<ResultLine> = {
    title: 'Nb App', key: 'deviceNbApp', dataIndex: 'deviceNbApp',
    ycSort: 'number',
    ycCanNotHide: true,
    /* render: (text, record) => (<div>
      {record.deviceNbApp}
      <AddFilter key="deviceApps" item="deviceApps" value={record.deviceNbApp} filters={filters} onSetFilter={onSetFilter} />
    </div>), */
  };

  const columDeviceNbAttempt: YcColumnType<ResultLine> = {
    title: 'Nb Attempt', key: 'deviceNbAttempt', dataIndex: 'deviceNbAttempt',
    ycSort: 'number',
    ycCanNotHide: true,
    /* render: (text, record) => (<div>
      {record.deviceNbAttempt}
      <AddFilter key="deviceAttempts" item="deviceAttempts" value={record.deviceNbAttempt} filters={filters} onSetFilter={onSetFilter} />
    </div>), */
  };

  const columRangeBidIr: YcColumnType<ResultLine> = {
    title: 'Range Bid IR', key: 'rangeBidIr', dataIndex: 'rangeBidIr',
    ycSort: 'number',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.rangeBidIr}
      <AddFilter key="rangeBidIrs" item="rangeBidIrs" value={record.rangeBidIr} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columHasIfa: YcColumnType<ResultLine> = {
    title: 'Has IDFA', key: 'hasIfa', dataIndex: 'hasIfa',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.hasIfa ? 'Yes' : 'No'}
      <AddFilter key="hasIfa" item="hasIfa" value={record.hasIfa} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  function Filter({ filterKey }: { filterKey: keyof ResultLine }) {
    return <HavingFilter
      onFilter={(v, c) => _setTableData(results?.results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
      onReset={() => _setTableData(results?.results || [])}
      filterKey={filterKey}
    />;
  }

  const columns: YcColumnType<ResultLine>[] = [
    {
      title: 'Impressions', dataIndex: 'impressions', key: 'impressions', ycSort: 'number',
      filterDropdown: <Filter filterKey='impressions' />,
      render: (text, record) => (<div className='cell-with-info'>
        {NumberTools.largeNumber(record.impressions)}
        {(record.brImpressions || 0) > 0 && <span className="cell-info">[{NumberTools.largeNumber(record.brImpressions)}]</span>}
        {(results?.total.impressions || 0) > 0 && <span className="cell-info">({NumberTools.roundPercentage(record.impressions * 100 / results!.total.impressions, 2)})</span>}
      </div>),
    },
    {
      title: 'Clicks', dataIndex: 'clicks', key: 'clicks', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.clicks),
      filterDropdown: <Filter filterKey='clicks' />,
    },
    {
      title: 'Installs', dataIndex: 'installs', key: 'installs', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.installs),
      filterDropdown: <Filter filterKey='installs' />
    },
    {
      title: 'CTR', dataIndex: 'ctr', key: 'ctr', ycSort: 'number',
      render: (text, record) => (<div className='cell-with-info'>
        {NumberTools.roundPercentage(record.ctr, 2)}
        {(record.brCtr || 0) > 0 && <span className="cell-info">[{NumberTools.roundPercentage(record.brCtr, 2)}]</span>}
      </div>),
      filterDropdown: <Filter filterKey='ctr' />
    },
    {
      title: 'IR', dataIndex: 'ir', key: 'ir', ycSort: 'number',
      render: (text, record) => (<div className='cell-with-info'>
        {NumberTools.roundPercentage(record.ir, 2)}
        {(record.brIr || 0) > 0 && <span className="cell-info">[{NumberTools.roundPercentage(record.brIr, 2)}]</span>}
      </div>),
      filterDropdown: <Filter filterKey='ir' />
    },
    {
      title: 'eCPI', dataIndex: 'ecpi', key: 'ecpi', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.ecpi, 3),
      filterDropdown: <Filter filterKey='ecpi' />
    },
    {
      title: 'eCPM', dataIndex: 'ecpm', key: 'ecpm', ycSort: 'number',
      render: (text, record) => (<div className='cell-with-info'>
        {NumberTools.roundPrice(record.ecpm, 3)}
        {(record.brEcpm || 0) > 0 && <span className="cell-info">[{NumberTools.roundPrice(record.brEcpm, 3)}]</span>}
      </div>),
      filterDropdown: <Filter filterKey='ecpm' />
    },
    {
      title: 'Spend', dataIndex: 'spend', key: 'spend', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.spend, 3),
      filterDropdown: <Filter filterKey='spend' />
    },
    ...(uAuths.isSuperAdmin() ? [{
      title: 'YSO Corp',
      dataIndex: 'ysocorp',
      key: 'ysocorp',
      children: [
        {
          title: 'Devices', dataIndex: 'devices', key: 'devices', ycSort: 'number', filterDropdown: <Filter filterKey='devices' />,
          render: (text, record) => (<div className='cell-with-info'>
            {NumberTools.largeNumber(record.devices)}
            {(record.devices || 0) > 0 && <span className="cell-info">({NumberTools.round(record.impressions / record.devices, 2)})</span>}
          </div>)
        },
        {
          title: 'eCPI', dataIndex: 'realEcpi', key: 'realEcpi', ycSort: 'number', filterDropdown: <Filter filterKey='realEcpi' />,
          render: (text, record) => NumberTools.roundPrice(record.realEcpi, 3),
        },
        {
          title: 'eCPM', dataIndex: 'realEcpm', key: 'realEcpm', ycSort: 'number', filterDropdown: <Filter filterKey='realEcpm' />,
          render: (text, record) => (<div className='cell-with-info'>
            {NumberTools.roundPrice(record.realEcpm, 3)}
            {(record.brRealEcpm || 0) > 0 && <span className="cell-info">[{NumberTools.roundPrice(record.brRealEcpm, 3)}]</span>}
          </div>),
        },
        {
          title: 'Spend', dataIndex: 'realSpend', key: 'realSpend', ycSort: 'number', defaultSortOrder: 'descend', filterDropdown: <Filter filterKey='realSpend' />,
          render: (text, record) => NumberTools.roundPrice(record.realSpend, 3),
        },
        {
          title: 'Bid IR', dataIndex: 'avgBidIr', key: 'avgBidIr', ycSort: 'number', filterDropdown: <Filter filterKey='avgBidIr' />,
          render: (text, record) => NumberTools.roundPercentage(record.avgBidIr, 2),
        },
        {
          title: 'Real Bid IR', dataIndex: 'avgRealBidIr', key: 'avgRealBidIr', ycSort: 'number', filterDropdown: <Filter filterKey='avgRealBidIr' />,
          render: (text, record) => NumberTools.roundPercentage(record.avgRealBidIr, 2),
        },
        {
          title: 'Marge', dataIndex: 'marge', key: 'marge', ycSort: 'number', filterDropdown: <Filter filterKey='marge' />,
          render: (text, record) => (<b style={{ "color": record.marge >= 0 ? 'green' : 'red', 'float': 'right' }}>{NumberTools.roundPrice(record.marge, 3)}</b>),
        },
        {
          title: 'ROI', dataIndex: 'roi', key: 'roi', ycSort: 'number', filterDropdown: <Filter filterKey='roi' />,
          render: (text, record) => (<b style={{ "color": record.roi >= 0 ? 'green' : 'red', 'float': 'right' }}>{NumberTools.roundPercentage(record.roi, 2)}</b>),
        },
        {
          title: 'Perf', dataIndex: 'perf', key: 'perf', ycSort: 'number', filterDropdown: <Filter filterKey='perf' />,
          render: (text, record) => (<b style={{ float: 'right' }}>{NumberTools.roundPercentage(record.perf, 2)}</b>),
        },
      ]
    }] : []) as YcColumnType<ResultLine>[],
    ...(uAuths.isSuperAdmin() ? [{
      title: 'ROAS',
      dataIndex: 'roas',
      key: 'roas',
      children: [
        { title: 'D0', dataIndex: 'r0', key: 'r0', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD0 + record.iapRevenueD0) / record.spend, 2) },
        { title: 'D1', dataIndex: 'r1', key: 'r1', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD1 + record.iapRevenueD1) / record.spend, 2) },
        { title: 'D2', dataIndex: 'r2', key: 'r2', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD2 + record.iapRevenueD2) / record.spend, 2) },
        { title: 'D3', dataIndex: 'r3', key: 'r3', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD3 + record.iapRevenueD3) / record.spend, 2) },
        { title: 'D7', dataIndex: 'r7', key: 'r7', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD7 + record.iapRevenueD7) / record.spend, 2) },
        { title: 'D14', dataIndex: 'r14', key: 'r14', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD14 + record.iapRevenueD14) / record.spend, 2) },
        { title: 'D30', dataIndex: 'r30', key: 'r30', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD30 + record.iapRevenueD30) / record.spend, 2) },
        { title: 'D45', dataIndex: 'r45', key: 'r45', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD45 + record.iapRevenueD45) / record.spend, 2) },
        { title: 'D60', dataIndex: 'r60', key: 'r60', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD60 + record.iapRevenueD60) / record.spend, 2) },
        { title: 'D75', dataIndex: 'r75', key: 'r75', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD75 + record.iapRevenueD75) / record.spend, 2) },
        { title: 'D90', dataIndex: 'r90', key: 'r90', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD90 + record.iapRevenueD90) / record.spend, 2) },
        { title: 'D180', dataIndex: 'r180', key: 'r180', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD180 + record.iapRevenueD180) / record.spend, 2) },
        { title: 'D365', dataIndex: 'r365', key: 'r365', ycSort: 'number', render: (text, record) => NumberTools.roundPercentage(100 * (record.revenueD365 + record.iapRevenueD365) / record.spend, 2) },
      ]
    }] : []) as YcColumnType<ResultLine>[],
  ];

  const columnsMemo = useMemo(() => {
    let cols: YcColumnType<ResultLine>[] = [...columns];
    let bCols: YcColumnType<ResultLine>[] = [];
    if (results?.is.includes("creativeType")) {
      bCols = [columCreativeType, ...bCols];
    }
    if (results?.is.includes("placementType")) {
      bCols = [columPlacementType, ...bCols];
    }
    if (results?.is.includes("adType")) {
      bCols = [columAdType, ...bCols];
    }
    if (results?.is.includes("ad")) {
      bCols = [columAdName, ...bCols];
    }
    if (results?.is.includes("source")) {
      bCols = [columSource, ...bCols];
    }
    if (results?.is.includes("country")) {
      bCols = [columCountry, ...bCols];
    }
    if (results?.is.includes("campaign")) {
      bCols = [columCampaign, ...bCols];
    }
    if (results?.is.includes("application")) {
      bCols = [columApplication, ...bCols];
    }
    if (results?.is.includes("platform")) {
      bCols = [columPlatform, ...bCols];
    }
    if (uAuths.isSuperAdmin()) {
      if (results?.is.includes("abTestVersion")) {
        bCols = [columAbTestVersion, ...bCols];
      }
      if (results?.is.includes("abTest")) {
        bCols = [columAbTest, ...bCols];
      }
      if (results?.is.includes("bidAbTest")) {
        bCols = [columBidAbTest, ...bCols];
      }
      if (results?.is.includes("adPhase")) {
        bCols = [columAdPhase, ...bCols];
      }
      if (results?.is.includes("placementPhase")) {
        bCols = [columPlacementPhase, ...bCols];
      }
      if (results?.is.includes("deviceNbAttempt")) {
        bCols = [columDeviceNbAttempt, ...bCols];
      }
      if (results?.is.includes("deviceNbApp")) {
        bCols = [columDeviceNbApp, ...bCols];
      }
      if (results?.is.includes("rangeBidIr")) {
        bCols = [columRangeBidIr, ...bCols];
      }
      if (results?.is.includes("advertiser")) {
        bCols = [columAdvertiser, ...bCols];
      }
      if (results?.is.includes("publisher")) {
        bCols = [columPublisher, ...bCols];
      }
      if (results?.is.includes("hasIfa")) {
        bCols = [columHasIfa, ...bCols];
      }
    }
    if (results?.is.includes("hourOfDay")) {
      bCols = [columHourOfDay, ...bCols];
    }
    if (results?.is.includes("granularity")) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        ycCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    {
      key: 'impressions',
      value: <div className='cell-with-info'>
        {NumberTools.largeNumber(results!.total.impressions)}
        {(results!.total.brImpressions || 0) > 0 && <span className="cell-info">[{NumberTools.largeNumber(results!.total.brImpressions)}]</span>}
      </div>
    },
    { key: 'clicks', value: NumberTools.largeNumber(results!.total.clicks) },
    { key: 'installs', value: NumberTools.largeNumber(results!.total.installs) },
    {
      key: 'ctr',
      value: <div className='cell-with-info'>
        {NumberTools.roundPercentage(results!.total.ctr, 2)}
        {(results!.total.brCtr || 0) > 0 && <span className="cell-info">[{NumberTools.roundPercentage(results!.total.brCtr, 2)}]</span>}
      </div>
    },
    {
      key: 'ir',
      value: <div className='cell-with-info'>
        {NumberTools.roundPercentage(results!.total.ir, 2)}
        {(results!.total.brIr || 0) > 0 && <span className="cell-info">[{NumberTools.roundPercentage(results!.total.brIr, 2)}]</span>}
      </div>
    },
    { key: 'ecpi', value: NumberTools.roundPrice(results!.total.ecpi, 3) },
    {
      key: 'ecpm',
      value: <div className='cell-with-info'>
        {NumberTools.roundPrice(results!.total.ecpm, 3)}
        {(results!.total.brEcpm || 0) > 0 && <span className="cell-info">[{NumberTools.roundPrice(results!.total.brEcpm, 3)}]</span>}
      </div>
    },
    { key: 'spend', value: NumberTools.roundPrice(results!.total.spend, 3) },
    ...(uAuths.isSuperAdmin() ? [
      {
        key: 'devices',
        value: <div className='cell-with-info'>
          {NumberTools.largeNumber(results!.total.devices)}
          {(results!.total.devices || 0) > 0 && <span className="cell-info">({NumberTools.round(results!.total.impressions / results!.total.devices, 2)})</span>}
        </div>
      },
      { key: 'realEcpi', value: NumberTools.roundPrice(results!.total.realEcpi, 3) },
      {
        key: 'realEcpm',
        value: <div className='cell-with-info'>
          {NumberTools.roundPrice(results!.total.realEcpm, 3)}
          {(results!.total.brRealEcpm || 0) > 0 && <span className="cell-info">[{NumberTools.roundPrice(results!.total.brRealEcpm, 3)}]</span>}
        </div>
      },
      { key: 'realSpend', value: NumberTools.roundPrice(results!.total.realSpend, 3) },
      { key: 'avgBidIr', value: NumberTools.roundPercentage(results!.total.avgBidIr, 2) },
      { key: 'avgRealBidIr', value: NumberTools.roundPercentage(results!.total.avgRealBidIr, 2) },
      { key: 'marge', value: <b style={{ "color": results!.total.marge >= 0 ? 'green' : 'red', 'float': 'right' }}>{NumberTools.roundPrice(results!.total.marge, 3)}</b> },
      { key: 'roi', value: <b style={{ "color": results!.total.roi >= 0 ? 'green' : 'red', 'float': 'right' }}>{NumberTools.roundPercentage(results!.total.roi, 2)}</b> },
      { key: 'perf', value: <b style={{ float: 'right' }}>{NumberTools.roundPercentage(results!.total.perf, 2)}</b> },
    ] : []),
    ...(uAuths.isSuperAdmin() ? [
      { key: 'r0', value: NumberTools.roundPercentage(100 * (results!.total.revenueD0 + results!.total.iapRevenueD0) / results!.total.spend, 2) },
      { key: 'r1', value: NumberTools.roundPercentage(100 * (results!.total.revenueD1 + results!.total.iapRevenueD1) / results!.total.spend, 2) },
      { key: 'r2', value: NumberTools.roundPercentage(100 * (results!.total.revenueD2 + results!.total.iapRevenueD2) / results!.total.spend, 2) },
      { key: 'r3', value: NumberTools.roundPercentage(100 * (results!.total.revenueD3 + results!.total.iapRevenueD3) / results!.total.spend, 2) },
      { key: 'r7', value: NumberTools.roundPercentage(100 * (results!.total.revenueD7 + results!.total.iapRevenueD7) / results!.total.spend, 2) },
      { key: 'r14', value: NumberTools.roundPercentage(100 * (results!.total.revenueD14 + results!.total.iapRevenueD14) / results!.total.spend, 2) },
      { key: 'r30', value: NumberTools.roundPercentage(100 * (results!.total.revenueD30 + results!.total.iapRevenueD30) / results!.total.spend, 2) },
      { key: 'r45', value: NumberTools.roundPercentage(100 * (results!.total.revenueD45 + results!.total.iapRevenueD45) / results!.total.spend, 2) },
      { key: 'r60', value: NumberTools.roundPercentage(100 * (results!.total.revenueD60 + results!.total.iapRevenueD60) / results!.total.spend, 2) },
      { key: 'r75', value: NumberTools.roundPercentage(100 * (results!.total.revenueD75 + results!.total.iapRevenueD75) / results!.total.spend, 2) },
      { key: 'r90', value: NumberTools.roundPercentage(100 * (results!.total.revenueD90 + results!.total.iapRevenueD90) / results!.total.spend, 2) },
      { key: 'r180', value: NumberTools.roundPercentage(100 * (results!.total.revenueD180 + results!.total.iapRevenueD180) / results!.total.spend, 2) },
      { key: 'r365', value: NumberTools.roundPercentage(100 * (results!.total.revenueD365 + results!.total.iapRevenueD365) / results!.total.spend, 2) },
    ] : []),
  ] : undefined;

  return (
    <div id='reporting-advertising'>
      <YcTitle label="Reporting - Advertising" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilterSuperAdmin service={reportingAdvertisingService_SA} onSearch={onSearch} onSetFilters={onSetFilters} filters={filters} type="advertising" />
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['spend', 'realSpend']} repeatFields />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-advertising"
              rowKey="id"
              rowClassName={(record) => (record.abTest === 'control' ? 'control-row' : '')}
              handleHiddenFieldsChange={setHiddenFields}
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
