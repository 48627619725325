import { useMemo, useState } from 'react';
import { Popover, Spin } from 'antd';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle } from "@/components";
import { NumberTools, UtilTools } from "@/tools";
import { reportingBiddingService_SA } from "@/services";
import { usePageTitle } from '@/hooks/use-title';

import { ReportingFilterSuperAdmin, FiltersSuperAdmin, HavingFilter, AddFilter } from '../../../components';

import './reporting-ssp.page.scss';


interface Total {
  requests: number;
  responses: number;
  noBids: number;
  bidRate: number;
  wins: number;
  winRate: number;
  loss: number;
  lossRate: number;
  errors: number;
  errorRate: number;
  bills: number;
  impressions: number;
  displayRate: number;
  clicks: number;
  clickRate: number;
  revenue: number;
  ecpm: number;
  loadStarts: number;
  loadEnds: number;
  loadErrors: number;
  showStarts: number;
  showEnds: number;
  showErrors: number;
  transfertCost: number;
}

interface ResultLine {
  id: number;
  requests: number;
  responses: number;
  noBids: number;
  bidRate: number;
  wins: number;
  winRate: number;
  loss: number;
  lossRate: number;
  errors: number;
  errorRate: number;
  bills: number;
  impressions: number;
  displayRate: number;
  clicks: number;
  clickRate: number;
  revenue: number;
  ecpm: number;
  loadStarts: number;
  loadEnds: number;
  loadErrors: number;
  showStarts: number;
  showEnds: number;
  showErrors: number;
  transfertCost: number;

  date?: string;
  hourOfDay?: string;
  bidder?: string;
  publisher?: string;
  endpoint?: string;
  platform?: string;
  platformVersion?: string;
  sdkVersion?: string;
  source?: string;
  placement?: string;
  placementType?: string;
  countryCode?: string;
  lossCode?: string;
  isMediation?: boolean;
  exAbTestKey?: string;
  exAbTestName?: string;
  exAbTestVersion?: string;
  bidAbTestKey?: string;
  bidAbTestName?: string;
  bidAbTestVersion?: string;
  deviceNbApp?: number;
  deviceNbAttempt?: number;
  adType?: string;
  sourceVersion?: string;
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

export function SAReportingSspPage(props: {}) {
  usePageTitle('Reporting - Ssp');
  const [filters, _setFilters] = useState<FiltersSuperAdmin | null>(null);
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);

  const onSetFilters = (filters) => {
    _setFilters(filters);
  }

  const onSearch = async (data: any) => {
    _setLoading(true);
    reportingBiddingService_SA.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
      }
    });
  }

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({ ...filters, [key]: filters![key].map(item => { if (item.value === value || item.label === value) { item.checked = checked; } return item; }) });
  }

  const columDate: YcColumnType<ResultLine> = {
    title: 'Date', key: 'date', dataIndex: 'date',
    ycSort: 'string',
    ycCanNotHide: true,
  };

  const columHourOfDay: YcColumnType<ResultLine> = {
    title: 'Hour', key: 'hourOfDay', dataIndex: 'hourOfDay',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.hourOfDay}
      <AddFilter key="hours" item="hours" value={record.hourOfDay} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columBidder: YcColumnType<ResultLine> = {
    title: 'Bidder', key: 'bidder', dataIndex: 'bidder',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.bidder}
      <AddFilter key="bidders" item="bidders" value={record.bidder} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPublisher: YcColumnType<ResultLine> = {
    title: 'Publisher', key: 'publisher', dataIndex: 'publisher',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.publisher}
      <AddFilter key="publishers" item="publishers" value={record.publisher} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columEndpoint: YcColumnType<ResultLine> = {
    title: 'Endpoint', key: 'endpoint', dataIndex: 'endpoint',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.endpoint}
      <AddFilter key="endpoints" item="endpoints" value={record.endpoint} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columSource: YcColumnType<ResultLine> = {
    title: 'Source', key: 'source', dataIndex: 'source',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.source}
      <AddFilter key="sources" item="sources" value={record.source} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacement: YcColumnType<ResultLine> = {
    title: 'Placement', key: 'placement', dataIndex: 'placement',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placement}
      <AddFilter key="placements" item="placements" value={record.placement} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlacementType: YcColumnType<ResultLine> = {
    title: 'Placement Type', key: 'placementType', dataIndex: 'placementType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.placementType}
      <AddFilter key="placementTypes" item="placementTypes" value={record.placementType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columCountry: YcColumnType<ResultLine> = {
    title: 'Country', key: 'countryCode', dataIndex: 'countryCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.countryCode}
      <AddFilter key="countries" item="countries" value={record.countryCode} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatform: YcColumnType<ResultLine> = {
    title: 'Platform', key: 'platform', dataIndex: 'platform',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platform}
      <AddFilter key="platforms" item="platforms" value={record.platform} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columPlatformVersion: YcColumnType<ResultLine> = {
    title: 'Platform Version', key: 'platformVersion', dataIndex: 'platformVersion',
    ycSort: 'numeric',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.platformVersion}
      <AddFilter key="platformVersions" item="platformVersions" value={record.platformVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columSdkVersion: YcColumnType<ResultLine> = {
    title: 'SDK Version', key: 'sdkVersion', dataIndex: 'sdkVersion',
    ycSort: 'numeric',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.sdkVersion}
      <AddFilter key="sdkVersions" item="sdkVersions" value={record.sdkVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columLossCode: YcColumnType<ResultLine> = {
    title: 'Loss Code', key: 'lossCode', dataIndex: 'lossCode',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.lossCode}
      <AddFilter key="lossCodes" item="lossCodes" value={record.lossCode} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columExAbTestKey: YcColumnType<ResultLine> = {
    title: 'Ex Ab Key', key: 'exAbTestKey', dataIndex: 'exAbTestKey',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.exAbTestKey}
      <AddFilter key="exAbTestKeys" item="exAbTestKeys" value={record.exAbTestKey} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columExAbTestName: YcColumnType<ResultLine> = {
    title: 'Ex Ab Name', key: 'exAbTestName', dataIndex: 'exAbTestName',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.exAbTestName}
      <AddFilter key="exAbTestNames" item="exAbTestNames" value={record.exAbTestName} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columExAbTestVersion: YcColumnType<ResultLine> = {
    title: 'Ex Ab Version', key: 'exAbTestVersion', dataIndex: 'exAbTestVersion',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.exAbTestVersion}
      <AddFilter key="exAbTestVersions" item="exAbTestVersions" value={record.exAbTestVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columBidAbTestKey: YcColumnType<ResultLine> = {
    title: 'Bid Ab Key', key: 'bidAbTestKey', dataIndex: 'bidAbTestKey',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.bidAbTestKey}
      <AddFilter key="bidAbTestKeys" item="bidAbTestKeys" value={record.bidAbTestKey} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columBidAbTestName: YcColumnType<ResultLine> = {
    title: 'Bid Ab Name', key: 'bidAbTestName', dataIndex: 'bidAbTestName',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.bidAbTestName}
      <AddFilter key="bidAbTestNames" item="bidAbTestNames" value={record.bidAbTestName} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columBidAbTestVersion: YcColumnType<ResultLine> = {
    title: 'Bid Ab Version', key: 'bidAbTestVersion', dataIndex: 'bidAbTestVersion',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.bidAbTestVersion}
      <AddFilter key="bidAbTestVersions" item="bidAbTestVersions" value={record.bidAbTestVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columSourceVersion: YcColumnType<ResultLine> = {
    title: 'Source Version', key: 'sourceVersion', dataIndex: 'sourceVersion',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.sourceVersion}
      <AddFilter key="sourceVersions" item="sourceVersions" value={record.sourceVersion} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columAdType: YcColumnType<ResultLine> = {
    title: 'Ad Type', key: 'adType', dataIndex: 'adType',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.adType}
      <AddFilter key="adTypes" item="adTypes" value={record.adType} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  const columDeviceNbApp: YcColumnType<ResultLine> = {
    title: 'Nb App', key: 'deviceNbApp', dataIndex: 'deviceNbApp',
    ycSort: 'number',
    ycCanNotHide: true,
  };

  const columDeviceNbAttempt: YcColumnType<ResultLine> = {
    title: 'Nb Attempt', key: 'deviceNbAttempt', dataIndex: 'deviceNbAttempt',
    ycSort: 'number',
    ycCanNotHide: true,
  };

  function Filter({ filterKey }: { filterKey: keyof ResultLine }) {
    return <HavingFilter
      onFilter={(v, c) => _setTableData(results?.results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
      onReset={() => _setTableData(results?.results || [])}
      filterKey={filterKey}
    />;
  }

  const columns: YcColumnType<ResultLine>[] = [
    {
      title: 'Requests', dataIndex: 'requests', key: 'requests', ycSort: 'number',
      filterDropdown: <Filter filterKey='requests' />,
      render: (text, record) => (<div className='cell-with-info'>
        {NumberTools.largeNumber(record.requests)}
        {(results?.total.requests || 0) > 0 && <span className="cell-info">({NumberTools.roundPercentage(record.requests * 100 / results!.total.requests, 2)})</span>}
      </div>),
    },
    {
      title: 'No Bids', dataIndex: 'noBids', key: 'noBids', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.noBids),
      filterDropdown: <Filter filterKey='noBids' />
    },
    {
      title: 'Responses', dataIndex: 'responses', key: 'responses', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.responses),
      filterDropdown: <Filter filterKey='responses' />
    },
    {
      title: 'Bid Rate', dataIndex: 'bidRate', key: 'bidRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.bidRate, 2),
      filterDropdown: <Filter filterKey='bidRate' />
    },
    {
      title: 'Wins', dataIndex: 'wins', key: 'wins', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.wins),
      filterDropdown: <Filter filterKey='wins' />
    },
    {
      title: 'Win Rate', dataIndex: 'winRate', key: 'winRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.winRate, 2),
      filterDropdown: <Filter filterKey='winRate' />
    },
    {
      title: 'Loss', dataIndex: 'loss', key: 'loss', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.loss),
      filterDropdown: <Filter filterKey='loss' />
    },
    {
      title: 'Loss Rate', dataIndex: 'lossRate', key: 'lossRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.lossRate, 2),
      filterDropdown: <Filter filterKey='lossRate' />
    },
    {
      title: 'Errors', dataIndex: 'errors', key: 'errors', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.errors),
      filterDropdown: <Filter filterKey='errors' />
    },
    {
      title: 'Error Rate', dataIndex: 'errorRate', key: 'errorRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.errorRate, 2),
      filterDropdown: <Filter filterKey='errorRate' />
    },
    {
      title: 'Loads', dataIndex: 'loadStarts', key: 'loadStarts', ycSort: 'number', filterDropdown: <Filter filterKey='loadStarts' />,
      render: (text, record) => (
        <Popover content={(
          <div>
            <div style={{ "fontSize": '10px' }}>LoadStarts: {NumberTools.largeNumber(record.loadStarts)}</div>
            <div style={{ "fontSize": '10px' }}>LoadEnds: {NumberTools.largeNumber(record.loadEnds)}<span>({UtilTools.computeAndFormatPercent(record.loadStarts, record.loadEnds, 2)})</span></div>
            <div style={{ "fontSize": '10px' }}>LoadErrors: {NumberTools.largeNumber(record.loadErrors)}<span>({UtilTools.computeAndFormatPercent(record.loadStarts, record.loadErrors, 2)})</span></div>
          </div>
        )} title="Load Details">
          <div>{NumberTools.largeNumber(record.loadStarts)}</div>
        </Popover>
      ),
    },
    {
      title: 'Shows', dataIndex: 'showStarts', key: 'showStarts', ycSort: 'number', filterDropdown: <Filter filterKey='showStarts' />,
      render: (text, record) => (
        <Popover content={(
          <div>
            <div style={{ "fontSize": '10px' }}>ShowStarts: {NumberTools.largeNumber(record.showStarts)}</div>
            <div style={{ "fontSize": '10px' }}>ShowEnds: {NumberTools.largeNumber(record.showEnds)}<span>({UtilTools.computeAndFormatPercent(record.showStarts, record.showEnds, 2)})</span></div>
            <div style={{ "fontSize": '10px' }}>ShowErrors: {NumberTools.largeNumber(record.showErrors)}<span>({UtilTools.computeAndFormatPercent(record.showStarts, record.showErrors, 2)})</span></div>
          </div>
        )} title="Show Details">
          <div>{NumberTools.largeNumber(record.showStarts)}</div>
        </Popover>
      ),
    },
    {
      title: 'Impressions', dataIndex: 'impressions', key: 'impressions', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.impressions),
      filterDropdown: <Filter filterKey='impressions' />
    },
    {
      title: 'Display Rate', dataIndex: 'displayRate', key: 'displayRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.displayRate, 2),
      filterDropdown: <Filter filterKey='displayRate' />
    },
    {
      title: 'Clicks', dataIndex: 'clicks', key: 'clicks', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.clicks),
      filterDropdown: <Filter filterKey='clicks' />,
    }, {
      title: 'Click Rate', dataIndex: 'clickRate', key: 'clickRate', ycSort: 'number',
      render: (text, record) => NumberTools.roundPercentage(record.clickRate, 2),
      filterDropdown: <Filter filterKey='clickRate' />
    },
    {
      title: 'Billable Impressions', dataIndex: 'bills', key: 'bills', ycSort: 'number',
      render: (text, record) => NumberTools.largeNumber(record.bills),
      filterDropdown: <Filter filterKey='bills' />
    },
    {
      title: 'Revenue', dataIndex: 'revenue', key: 'revenue', defaultSortOrder: 'descend', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.revenue, 3),
      filterDropdown: <Filter filterKey='revenue' />
    },
    {
      title: 'eCPM', dataIndex: 'ecpm', key: 'ecpm', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.ecpm, 3),
      filterDropdown: <Filter filterKey='ecpm' />
    },
    {
      title: 'Data Transfert', dataIndex: 'transfertCost', key: 'transfertCost', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.transfertCost, 3),
      filterDropdown: <Filter filterKey='transfertCost' />
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: YcColumnType<ResultLine>[] = [...columns];
    let bCols: YcColumnType<ResultLine>[] = [];
    if (results?.is.includes("country")) {
      bCols = [columCountry, ...bCols];
    }
    if (results?.is.includes("adType")) {
      bCols = [columAdType, ...bCols];
    }
    if (results?.is.includes("sourceVersion")) {
      bCols = [columSourceVersion, ...bCols];
    }
    if (results?.is.includes("placementType")) {
      bCols = [columPlacementType, ...bCols];
    }
    if (results?.is.includes("source")) {
      bCols = [columSource, ...bCols];
    }
    if (results?.is.includes("platform")) {
      bCols = [columPlatform, ...bCols];
    }
    if (results?.is.includes("platformVersion")) {
      bCols = [columPlatformVersion, ...bCols];
    }
    if (results?.is.includes("sdkVersion")) {
      bCols = [columSdkVersion, ...bCols];
    }
    if (results?.is.includes("lossCode")) {
      bCols = [columLossCode, ...bCols];
    }
    if (results?.is.includes("placement")) {
      bCols = [columPlacement, ...bCols];
    }
    if (results?.is.includes("bidAbTestVersion")) {
      bCols = [columBidAbTestVersion, ...bCols];
    }
    if (results?.is.includes("bidAbTestName")) {
      bCols = [columBidAbTestName, ...bCols];
    }
    if (results?.is.includes("bidAbTestKey")) {
      bCols = [columBidAbTestKey, ...bCols];
    }
    if (results?.is.includes("exAbTestVersion")) {
      bCols = [columExAbTestVersion, ...bCols];
    }
    if (results?.is.includes("exAbTestName")) {
      bCols = [columExAbTestName, ...bCols];
    }
    if (results?.is.includes("exAbTestKey")) {
      bCols = [columExAbTestKey, ...bCols];
    }
    if (results?.is.includes("bidder")) {
      bCols = [columBidder, ...bCols];
    }
    if (results?.is.includes("publisher")) {
      bCols = [columPublisher, ...bCols];
    }
    if (results?.is.includes("endpoint")) {
      bCols = [columEndpoint, ...bCols];
    }
    if (results?.is.includes("deviceNbAttempt")) {
      bCols = [columDeviceNbAttempt, ...bCols];
    }
    if (results?.is.includes("deviceNbApp")) {
      bCols = [columDeviceNbApp, ...bCols];
    }
    if (results?.is.includes("hourOfDay")) {
      bCols = [columHourOfDay, ...bCols];
    }
    if (results?.is.includes("granularity")) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        ycCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    { key: 'requests', value: NumberTools.largeNumber(results!.total.requests) },
    { key: 'noBids', value: NumberTools.largeNumber(results!.total.noBids) },
    { key: 'responses', value: NumberTools.largeNumber(results!.total.responses) },
    { key: 'bidRate', value: NumberTools.roundPercentage(results!.total.bidRate, 2) },
    { key: 'wins', value: NumberTools.largeNumber(results!.total.wins) },
    { key: 'winRate', value: NumberTools.roundPercentage(results!.total.winRate, 2) },
    { key: 'loss', value: NumberTools.largeNumber(results!.total.loss) },
    { key: 'lossRate', value: NumberTools.roundPercentage(results!.total.lossRate, 2) },
    { key: 'errors', value: NumberTools.largeNumber(results!.total.errors) },
    { key: 'errorRate', value: NumberTools.roundPercentage(results!.total.errorRate, 2) },
    {
      key: 'loadStarts',
      value: <Popover content={(
        <div>
          <div style={{ "fontSize": '10px' }}>LoadStarts: {NumberTools.largeNumber(results!.total.loadStarts)}</div>
          <div style={{ "fontSize": '10px' }}>LoadEnds: {NumberTools.largeNumber(results!.total.loadEnds)}<span>({UtilTools.computeAndFormatPercent(results!.total.loadStarts, results!.total.loadEnds, 2)})</span></div>
          <div style={{ "fontSize": '10px' }}>LoadErrors: {NumberTools.largeNumber(results!.total.loadErrors)}<span>({UtilTools.computeAndFormatPercent(results!.total.loadStarts, results!.total.loadErrors, 2)})</span></div>
        </div>
      )} title="Load Details">
        <div>{NumberTools.largeNumber(results!.total.loadStarts)}</div>
      </Popover>
    },
    {
      key: 'showStarts',
      value: <Popover content={(
        <div>
          <div style={{ "fontSize": '10px' }}>ShowStarts: {NumberTools.largeNumber(results!.total.showStarts)}</div>
          <div style={{ "fontSize": '10px' }}>ShowEnds: {NumberTools.largeNumber(results!.total.showEnds)}<span>({UtilTools.computeAndFormatPercent(results!.total.showStarts, results!.total.showEnds, 2)})</span></div>
          <div style={{ "fontSize": '10px' }}>ShowErrors: {NumberTools.largeNumber(results!.total.showErrors)}<span>({UtilTools.computeAndFormatPercent(results!.total.showStarts, results!.total.showErrors, 2)})</span></div>
        </div>
      )} title="Show Details">
        <div>{NumberTools.largeNumber(results!.total.showStarts)}</div>
      </Popover>
    },
    { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
    { key: 'displayRate', value: NumberTools.roundPercentage(results!.total.displayRate, 2) },
    { key: 'clicks', value: NumberTools.largeNumber(results!.total.clicks) },
    { key: 'clickRate', value: NumberTools.roundPercentage(results!.total.clickRate, 2) },
    { key: 'bills', value: NumberTools.largeNumber(results!.total.bills) },
    { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
    { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
    { key: 'transfertCost', value: NumberTools.roundPrice(results!.total.transfertCost, 3) },
  ] : undefined;

  return (
    <div id='reporting-ssp'>
      <YcTitle label="Reporting - Ssp" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilterSuperAdmin service={reportingBiddingService_SA} onSearch={onSearch} onSetFilters={onSetFilters} filters={filters} type="ssp"></ReportingFilterSuperAdmin>
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue', 'transfertCost']} repeatFields />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-bidding"
              rowKey="id"
              rowClassName={(record) => (record.bidAbTestName === 'control' ? 'control-row' : '')}
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
