import * as OpenRTB_2_5_0 from './OpenRTB-2-5-0';
import * as Vast from './Vast';
import * as YcTypes from './types';
import * as YcEnums from './enums';

export * as YcCountries from './countries';
export * as YcVideoSizes from './videoSizes';
export {
  OpenRTB_2_5_0, Vast, YcTypes, YcEnums,
};

// Typescript utils types

export type PickPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type OmitPartial<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
};

type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];

export type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>;

export type Combine<A, B> =
  Omit<A, keyof B> // items in A that aren't in B
  & Omit<B, keyof A> // items in B that aren't in A
  & { [K in keyof A & keyof B]: A[K] | B[K] }; // union of both.

export interface PermissionType {
  [key: string]: {
    children?: PermissionType;
  } | boolean;
}
