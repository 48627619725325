import { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { ColumnType } from "antd/lib/table";
import { CaretRightOutlined } from '@ant-design/icons';

import { MUser } from '../../../../modeles';
import { YcTitle } from '../../../../components';
import { usersService } from '../../../../services/users.service';

import './users.page.scss';
import { usePageTitle } from '@/hooks/use-title';

export function UsersPage() {
  usePageTitle('Users');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/users',
    title: 'Users'
  });

  const [users, _setUsers] = useState<MUser[]>([]);

  useEffect(() => {
    usersService.listing().then((a) => {
      _setUsers(a || []);
    });
  }, []);

  const columns: ColumnType<MUser>[] = [
    {
      title: 'ID', key: 'id',
      render: (text: string, user: MUser) => (
        <Link to={"/users/user/" + user.id}>#{user.id}</Link>
      ),
    },
    {
      title: 'Email', key: 'email',
      render: (text: string, user: MUser) => (
        <Link to={"/users/user/" + user.id}>{user.email}</Link>
      ),
    },
    { title: 'Admin', key: 'isAdmin', dataIndex: 'isAdmin',
      render: (text: string, user: MUser) => (
        <>{user.isAdmin ? "Yes" : "No"}</>
      )
    },
    {
      title: 'Active',
      key: 'isActive',
      render: (text: string, campaign: MUser) => (
        <>
          {!campaign.isAdmin &&
            <Button onClick={async () => {
              if (campaign) {
                _setUsers(await usersService.activate(campaign.id, !campaign.isActive));
              }
            }} type="primary" danger={!campaign.isActive} shape="circle" icon={<CaretRightOutlined />} />
          }
        </>
      ),
      filters: [{ text: 'Active', value: true }, { text: 'Inactive', value: false }],
      onFilter: (value, record) => record.isActive === value,
    },
  ];

  return (
   <div id="root-users">
     <YcTitle label="Users" addUrl="/users/user"></YcTitle>
     <Table bordered size="small" dataSource={users} columns={columns} rowKey="id"></Table>
   </div>
  );
}
