import { Button, Input, Select, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { YcCountries } from 'yc-interfaces';
import { MCampaign, MCampaignCountry } from '../../../../modeles';

// styles
import './campaign-countries.scss';

const COUNTRIES_T1: { [code: string]: string } = YcCountries.COUNTRIES_ISO2_UA;

export function CampaignCountries(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
}) {

  const columns: ColumnType<MCampaignCountry>[] = [
    {
      title: 'Country',
      key: 'country',
      dataIndex: 'key',
      sorter: (a, b) => a.key.localeCompare(b.key || '') || -1,
    },
    {
      title: 'Cpi', key: 'cpi',
      render: (text: string, country: MCampaignCountry) => (
        <Input type="number" step="0.001" min="0.001" max="10" defaultValue={country.cpi} onChange={(val) => {
          country.cpi = parseFloat(val.target.value);
          props._setCampaign({ ...props.campaign });
        }} />
      ),
      sorter: (a, b) => a.cpi - b.cpi,
    },
    {
      title: 'Status', dataIndex: 'isActive', key: 'isActive',
      sorter: (a: MCampaignCountry, b: MCampaignCountry) => Number(a.isActive) - Number(b.isActive),
      render: (text: string, country: MCampaignCountry) => 
        <div className="block-bt link-withicon" onClick={async () => {
            country.isActive = !country.isActive;
            props._setCampaign({
              ...props.campaign,
              countries: [
                ...props.campaign.countries
              ]
            });
          }}>
          {country.isActive && <div><PlayCircleOutlined style={{color: 'green' }} /> <b>Active</b><PauseCircleOutlined style={{color: 'red' }} className="lw-icon"/></div>}
          {!country.isActive && <div><PauseCircleOutlined style={{color: 'red' }} /> <b>Inactive</b><PlayCircleOutlined  style={{color: 'green' }}  className="lw-icon"/></div>}
        </div>,
      filters: [{ text: 'Active', value: true }, { text: 'Inactive', value: false }],
      onFilter: (value, record) => record.isActive === value,
    },
    {
      title: 'Delete', key: 'delete',
      render: (text: string, country: MCampaignCountry) => (
        <>
          <Tooltip title="Delete">
            <Button onClick={() => {
              props._setCampaign({
                ...props.campaign,
                countries: props.campaign.countries.filter((e) => {
                  return e.key !== country.key;
                })
              });
            }} type="primary" shape="circle" size="small" danger icon={<DeleteOutlined />} />
          </Tooltip>
        </>
      ),
    },
  ];

  const onAdd = (country: any) => {
    props._setCampaign({
      ...props.campaign,
      countries: [
        ...props.campaign.countries,
        {
          key: country,
          cpi: 1,
          isActive: true,
        }
      ]
    })
  };

  const getCountriesToAdd = () => {
    return Object.entries(COUNTRIES_T1).filter((object) => {
      return props.campaign.countries.find((c) => {
        return c.key === object[0];
      }) === undefined;
    });
  }

  const handleSearch = (input: string, option?: { value: string }) => {
    const country = option?.value;
    const lowerInput = input.toLowerCase();
    return country ? country.toLowerCase().includes(lowerInput) || COUNTRIES_T1[country].toLowerCase().includes(lowerInput) : false;
  }

  return (
    <div className="c-campaign-countries">
      <Table bordered size="small" dataSource={props.campaign.countries} columns={columns} rowKey="key" pagination={false}></Table>
      <Select onSelect={onAdd} showSearch placeholder="Add country" className='mt-2 mb-4 country-select' filterOption={handleSearch}>
        {getCountriesToAdd().map((data: any) => (
          <Select.Option key={data[0]} value={data[0]}>{data[0]} - {data[1]}</Select.Option>
        ))}
      </Select>
    </div>
  );
}