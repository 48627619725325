import ReactGA from 'react-ga4';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import {
  DocsSdkUnityPage,

  DocsManageAppsPage,
  DocsManageCampaignsPage,
  DocsManageCreativesPage,
  DocsManagePlacementsPage,

  DocsReportAdvertisingPage,
  DocsReportPublishingPage,
  DocsReportBiddingPage,
} from './pages';

import "./index.scss";
import { ProtectedComponent } from "../../components";
import { usePageTitle } from "@/hooks/use-title";

export function DocsModule() {
  usePageTitle('Documentation');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/docs',
    title: 'Documentation'
  });

  const { path } = useRouteMatch();

  return (
    <div id="docs">
      <Switch>
        <Route exact path={`${path}/sdk/unity`}><ProtectedComponent permission="documentation/unity"><DocsSdkUnityPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/manage/apps`}><ProtectedComponent permission="applications/documentation"><DocsManageAppsPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/manage/campaigns`}><ProtectedComponent permission="advertising/campaigns/documentation"><DocsManageCampaignsPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/manage/creatives`}><ProtectedComponent permission="advertising/campaigns/documentation"><DocsManageCreativesPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/manage/placements`}><ProtectedComponent permission="publishing/placements/documentation"><DocsManagePlacementsPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/report/advertising`}><ProtectedComponent permission="advertising/reports/documentation"><DocsReportAdvertisingPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/report/publishing`}><ProtectedComponent permission="publishing/reports/documentation"><DocsReportPublishingPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/report/bidding`}><ProtectedComponent permission="bidding/reports/documentation"><DocsReportBiddingPage /></ProtectedComponent></Route>
        <Route path={path}><Redirect to="/" /></Route>
      </Switch>
    </div>
  );

}
