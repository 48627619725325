import { usePageTitle } from '@/hooks/use-title';
import { YcFooter, YcHeaderTitle, YcDocReader } from '@/components';

import './privacy.page.scss';
import ReactGA from 'react-ga4';

export function Privacy() {
  usePageTitle('privacy');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/privacy',
    title: 'privacy'
  });

  const headerTitle = <h1>Privacy Policy</h1>

  return (
    <div id="root-privacy">
      <YcHeaderTitle title={headerTitle} />
      <div className='container d-flex justify-content-center mt-5'>
        <YcDocReader file='/ysonetworkprivacy.html'/>
      </div>
      <YcFooter />
    </div>
  );
}


