export class NumberTools {

  static round(num: number, nbAfterComma: number) {
    if (num === undefined) {
      return undefined;
    }
    if (num !== 0) {
      const pow = Math.pow(10, nbAfterComma);
      return Math.round(num * pow) / pow;  
    }
    return 0;
  }

  static roundPrice(num: number, nbAfterComma: number) {
    const price = NumberTools.round(num, nbAfterComma);
    if (price !== undefined) {
      return "$" + price.toLocaleString('en', { useGrouping:true });
    }
    return undefined;
  }

  static roundPercentage(num: number, nbAfterComma: number) {
    const percent = NumberTools.round(num, nbAfterComma);
    if (percent !== undefined) {
      return percent + "%";
    }
    return undefined;
  }

  static largeNumber(num: number) {
    if (num === undefined) {
      return undefined;
    }
    if (num !== 0) {
      return num.toLocaleString('en', { useGrouping:true });
    }
    return 0;
  }

}