import { usePageTitle } from '@/hooks/use-title';
import ReactGA from 'react-ga4';
import { YcTitle } from '../../../../components';

import './404.page.scss';

export function P404Page() {
  usePageTitle('404 - Not Found');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/404',
    title: '404 - Not Found'
  });

  return (
    <div className="root-404">
      <YcTitle label="404 - Not Found"></YcTitle>
      <footer>
        <div className="bkg-footer" />
      </footer>
    </div>
  );
}
