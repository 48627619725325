export enum BidAbTests {
  CONTROL = 'control',
  TEST_DEVICE = 'test-device',
  NO_BID = 'no-bid',
  LEARNING = 'learning',

  // Testing abs
  NO_BID_FLOOR = 'no-bid-floor',
  ROI_REG_OFF = 'roi-reg-off',
  ROI_REG_10 = 'roi-reg-10',
  ROI_REG_NO_MAX = 'roi-reg-no-max',
  ROI_REG_NO_MAX_50 = 'roi-reg-no-max-50',
  DISABLE_BONUS = 'disable-bonus',
  DISABLE_BONUS_M = 'disable-bonus-m',
  DISABLE_BONUS_UA = 'disable-bonus-ua',
  LOW_ASSET_QUALITY = 'low-asset-quality',
  HIGH_ASSET_QUALITY = 'high-asset-quality',
  ORIGINAL_ASSET_QUALITY = 'original-asset-quality',
  WEBVIEW_LOAD = 'webview-load',
  SDK_LOAD = 'sdk-load',
  AD_COMPUTE_ICON_FIRST = 'ad-compute-icon-first',
  IMP_X = 'imp-x',
  IMP_X_2 = 'imp-x-2',
  IMP_X_3 = 'imp-x-3',
  DIRECT_TRACK = 'direct-track',
  DIRECT_TRACK_ONLY = 'direct-track-only',
  REMOVE_BATCH_TRACK = 'remove-batch-track',
  MAX_BID_IR_NO_LIMIT = 'max-bid-ir-no-limit',
  FORCED_LEARNING = 'forced-learning',
  TOP_CREA = 'top-crea',
  TOP_CREA_PRED = 'top-crea-pred',
  MODEL_CONTROL = 'model-control',
  MODEL_ATTEMPTS_MAX = 'model-attempts-max',
  MODEL_ATTEMPTS_MAX_BIS = 'model-attempts-max-bis',
  CLIENT_M_NO_MULTIPLIER = 'client-m-no-multiplier',
  DEVICE_PRICE_NB_APPS = 'device-price-nb-apps',
  LIMIT_MAX_BID_IR_DIFF = 'limit-max-bid-ir-diff'
}

export default BidAbTests;
