import { useMemo } from 'react';
import { Spin, Table, Tooltip } from 'antd';
import { LinkOutlined, AndroidFilled, AppleFilled, EditOutlined } from '@ant-design/icons';
import { MApp } from '@/modeles';
import { SearchBar, YcIconName, YcTitle } from '@/components';

import './apps.page.scss';
import { ColumnType } from "antd/lib/table";
import ReactGA from 'react-ga4';
import { useApps } from './use-apps';
import { usePageTitle } from '@/hooks/use-title';
import { Link } from 'react-router-dom';

export function AppsPage() {
  usePageTitle('Applications');

  ReactGA.send({
    hitType: 'pageView',
    pagePath: '/apps',
    title: 'Applications'
  });

  const { loading, onSearch, displayApps } = useApps();

  const columns: ColumnType<MApp>[] = useMemo(() => [
    {
      title: 'Name', key: 'name',
      render: (text: string, record: MApp) => (
        <Tooltip title="Edit">
          <Link to={"/apps/app/" + record.key} className='link-withicon'>
            <YcIconName app={record}></YcIconName>
            <EditOutlined className='lw-icon' />
          </Link>
        </Tooltip>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'Key', key: 'key', dataIndex: 'key',
      sorter: (a, b) => a.key.localeCompare(b.key || '') || -1,
    },
    {
      title: 'Platform', key: 'platform',
      render: (text: string, app: MApp) => (
        <>
          {app.platform === "android" && <AndroidFilled />}
          {app.platform === "ios" && <AppleFilled />}
          &nbsp;{app.platform}
        </>
      ),
      sorter: (a, b) => a.platform.localeCompare(b.platform || '') || -1,
      filters: [{ text: 'Android', value: 'android' }, { text: 'iOS', value: 'ios' }],
      onFilter: (value, record) => record.platform?.toLowerCase() === (value as string).toLowerCase(),
    },
    { title: 'Bundle ID', key: 'bundleId', dataIndex: 'bundleId', sorter: (a, b) => a.bundleId.localeCompare(b.bundleId || '') || -1 },
    {
      title: 'Campaigns', key: 'campaignNb', render: (text: string, app: MApp) => (
        <><b>Active {(app.nbCampaignsActive || 0)}</b> / {app.nbCampaigns}</>
      )
    },
    {
      title: 'Placements', key: 'placementNb', render: (text: string, app: MApp) => (
        <><b>Active {(app.nbPlacementsActive || 0)}</b> / {app.nbPlacements}</>
      )
    },
    {
      title: 'Store Url',
      key: 'url',
      render: (text: string, record: MApp) => (
        <a className='link-withicon' target="_blank" rel="noreferrer" href={record.storeUrl}><LinkOutlined className='lw-icon' /> Link</a>
      ),
    },
  ], []);

  return (
    <div id='apps-apps'>
      <YcTitle label="Applications" addUrl="/apps/app"></YcTitle>
      <Spin spinning={loading}>
        <div className='content'>
          <SearchBar placeholder="Filter by app name or bundle" onSearch={onSearch} />
          <Table bordered size="small" dataSource={displayApps} columns={columns} rowKey="key" />
        </div>
      </Spin>
    </div>
  );
}
