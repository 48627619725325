import qs from "qs";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Form, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { Permissions } from "@/modeles";
import { useAuths } from '@/contexts/auths.context';
import { YcFieldCheckPage, FCItem, YcDatePicker, } from "@/components";

import "./reporting-filter.component.scss";
import { ReportingPublishingService, ReportingAdvertisingService, ReportingBiddingService } from "@/services";

export interface Filters {
  hours: FCItem[];
  endpoints: FCItem[];
  platforms: FCItem[];
  platformVersions: FCItem[];
  applications: FCItem[];
  campaigns: FCItem[];
  placements: FCItem[];
  placementTypes: FCItem[];
  lossCodes: FCItem[];
  sources: FCItem[];
  creativeTypes: FCItem[];
  countries: FCItem[];
  ads: FCItem[];
  granularities: FCItem[];

  isGranularity: boolean;
  isHourOfDay: boolean;
  isEndpoint: boolean;
  isPlatform: boolean;
  isPlatformVersion: boolean;
  isApplication: boolean;
  isCampaign: boolean;
  isAd: boolean;
  isPlacement: boolean;
  isPlacementType: boolean;
  isLossCode: boolean;
  isSource: boolean;
  isCreativeType: boolean;
  isCountry: boolean;
}

function canAccess(path: string, permissions: Permissions | null, superAdmin: boolean) {
  return (superAdmin) || (path && permissions && permissions.isAllowed(path));
}

export function ReportingFilter(props: {
  onSearch: (data: any) => void;
  onSetFilters: (filters: any) => void;
  filters: any;
  type: ('advertising' | 'publishing' | 'bidding'),
  service: ReportingAdvertisingService | ReportingBiddingService | ReportingPublishingService,
}) {

  const [dates, _setDates] = useState<[moment.Moment, moment.Moment]>([moment().subtract(1, 'day'), moment().subtract(1, 'day')]);
  const [loading, _setLoading] = useState<boolean>(false);

  const { permissions, isSuperAdmin } = useAuths();

  const canSeeSourceAdv = useMemo(
    () => props.type !== 'advertising' || canAccess('advertising/reports/filters/source', permissions, isSuperAdmin()),
    [props.type, permissions, isSuperAdmin],
  );
  const canSeePlacementTypeAdv = useMemo(
    () => props.type !== 'advertising' || canAccess('advertising/reports/filters/placement_type', permissions, isSuperAdmin()),
    [props.type, permissions, isSuperAdmin],
  );
  const canSeeCreativeTypeAdv = useMemo(
    () => props.type !== 'advertising' || canAccess('advertising/reports/filters/creative_type', permissions, isSuperAdmin()),
    [props.type, permissions, isSuperAdmin],
  );
  const canSeeAdAdv = useMemo(
    () => props.type !== 'advertising' || canAccess('advertising/reports/filters/ad', permissions, isSuperAdmin()),
    [props.type, permissions, isSuperAdmin],
  );

  const uLocation = useLocation();
  const uHistory = useHistory();

  const SetParamsFilter = (items: FCItem[], paramsString: string) => {
    if (paramsString) {
      const params = paramsString.split(',');
      items.forEach((item) => {
        if (params.includes(item.value + "-Y")) {
          item.checked = true;
        } else if (params.includes(item.value + "-N")) {
          item.checked = false;
        }
      });
    }
  }

  useEffect(() => {
    _setLoading(true);
    const search = new URLSearchParams(uLocation.search);
    const params: { granularity: string | null } = { granularity: null };
    if (search.has('dateStart')) {
      dates[0] = moment(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = moment(search.get('dateEnd'));
    }
    if (search.has('is') && search.has('granularity')) {
      const is = search.get('is')!.split(',');
      if (is.includes('granularity')) {
        params.granularity = search.get('granularity');
      }
    }
    props.service.filters(getParams(false, params)).then((data: Filters) => {
      _setLoading(false);
      if (data) {
        SetParamsFilter(data.granularities, search.get('granularities')!);
        SetParamsFilter(data.hours, search.get('hours')!);
        SetParamsFilter(data.countries, search.get('countries')!);
        SetParamsFilter(data.platforms, search.get('platforms')!);
        SetParamsFilter(data.platformVersions, search.get('platformVersions')!);
        if (canSeeSourceAdv) {
          SetParamsFilter(data.sources, search.get('sources')!);
        }
        if (canSeePlacementTypeAdv) {
          SetParamsFilter(data.placementTypes, search.get('placementTypes')!);
        }
        if (props.type === 'advertising') {
          SetParamsFilter(data.applications, search.get('applications')!);
          SetParamsFilter(data.campaigns, search.get('campaigns')!);
          SetParamsFilter(data.creativeTypes, search.get('creativeTypes')!);
        }
        if (props.type === 'publishing') {
          SetParamsFilter(data.placements, search.get('placements')!);
        }
        if (props.type === 'bidding') {
          SetParamsFilter(data.lossCodes, search.get('lossCodes')!);
        }
        if (search.has('is')) {
          const is = search.get('is')!.split(',');
          data.isGranularity = is.includes('granularity');
          data.isHourOfDay = is.includes('hourOfDay');
          data.isApplication = is.includes('application');
          data.isCampaign = is.includes('campaign');
          data.isAd = is.includes('ad');
          data.isCountry = is.includes('country');
          data.isEndpoint = is.includes('endpoint');
          data.isPlatform = is.includes('platform');
          data.isPlatformVersion = is.includes('platformVersion');
          data.isPlacementType = is.includes('placementType');
          data.isPlacement = is.includes('placement');
          data.isLossCode = is.includes('lossCode');
          data.isSource = is.includes('source');
          data.isCreativeType = is.includes('creativeType');
        }
        props.onSetFilters(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParamsFilter = (items: FCItem[] = []) => {
    const results = items.filter((item) => item.checked !== undefined);
    return results.map((item) => item.value + "-" + (item.checked ? "Y" : "N")).join() || undefined;
  }
  const getParamsIs = () => {
    const is: string[] = [];
    if (props.filters?.isGranularity) { is.push("granularity"); }
    if (props.filters?.isHourOfDay) { is.push("hourOfDay"); }
    if (props.filters?.isApplication) { is.push("application"); }
    if (props.filters?.isCampaign) { is.push("campaign"); }
    if (props.filters?.isSource) { is.push("source"); }
    if (props.filters?.isCreativeType) { is.push("creativeType"); }
    if (props.filters?.isAd) { is.push("ad"); }
    if (props.filters?.isPlacementType) { is.push("placementType"); }
    if (props.filters?.isPlacement) { is.push("placement"); }
    if (props.filters?.isLossCode) { is.push("lossCode"); }
    if (props.filters?.isCountry) { is.push("country"); }
    if (props.filters?.isEndpoint) { is.push("endpoint"); }
    if (props.filters?.isPlatform) { is.push("platform"); }
    if (props.filters?.isPlatformVersion) { is.push("platformVersion"); }
    if (is.length) {
      return is.join(',');
    }
    return undefined;
  }

  const getParams = (stringify: boolean, params: any = {}) => {

    const b = {
      ...(params || {}),
      dateStart: moment(dates[0]).format("YYYY-MM-DD"),
      dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
      granularity: props.filters?.isGranularity ? props.filters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
      hours: getParamsFilter(props.filters?.hours),
      endpoints: props.type === 'bidding' ? getParamsFilter(props.filters?.endpoints) : undefined,
      countries: getParamsFilter(props.filters?.countries),
      sources: canSeeSourceAdv && getParamsFilter(props.filters?.sources),
      platforms: getParamsFilter(props.filters?.platforms),
      platformVersions: getParamsFilter(props.filters?.platformVersions),
      sdkVersions: getParamsFilter(props.filters?.sdkVersions),
      placementTypes: canSeePlacementTypeAdv && getParamsFilter(props.filters?.placementTypes),
      placements: props.type === 'publishing' ? getParamsFilter(props.filters?.placements) : undefined,
      applications: props.type === 'advertising' ? getParamsFilter(props.filters?.applications) : undefined,
      campaigns: props.type === 'advertising' ? getParamsFilter(props.filters?.campaigns) : undefined,
      creativeTypes: props.type === 'advertising' ? getParamsFilter(props.filters?.creativeTypes) : undefined,
      lossCodes: props.type === 'bidding' ? getParamsFilter(props.filters?.lossCodes) : undefined,
      is: getParamsIs(),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  }

  return (
    <div id='reporting-filter'>
      <Spin spinning={loading}>
        {!!props.filters &&
          <Form>
            <Form.Item>
              <YcDatePicker value={dates} onChange={(e) => {
                if (e && e.length >= 2) {
                  _setDates([e[0]!, e[1]!]);
                }
              }}
                presets={['Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date']}
                disabledDate={d => !d || d.isAfter(moment().utc().subtract(1, 'day'))} />
            </Form.Item>
            <Form.Item>
              <YcFieldCheckPage is={props.filters!.isGranularity} label="Granularities" items={props.filters!.granularities}
                onChange={(granularities, isGranularity): void => {
                  props.onSetFilters({ ...props.filters, granularities, isGranularity });
                }}></YcFieldCheckPage>
            </Form.Item>
            <Form.Item>
              <YcFieldCheckPage is={props.filters!.isHourOfDay} label="Hours" items={props.filters!.hours} mode="multiple"
                onChange={(hours, isHourOfDay): void => {
                  props.onSetFilters({ ...props.filters, hours, isHourOfDay });
                }}></YcFieldCheckPage>
            </Form.Item>
            {props.type === 'bidding' &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isEndpoint} label="Endpoints" items={props.filters!.endpoints} mode="multiple"
                  onChange={(endpoints, isEndpoint): void => {
                    props.onSetFilters({ ...props.filters, endpoints, isEndpoint });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            <Form.Item>
              <YcFieldCheckPage is={props.filters!.isCountry} label="Countries" items={props.filters!.countries} mode="multiple"
                onChange={(countries, isCountry): void => {
                  props.onSetFilters({ ...props.filters, countries, isCountry });
                }}></YcFieldCheckPage>
            </Form.Item>
            {canSeeSourceAdv && <Form.Item>
              <YcFieldCheckPage is={props.filters!.isSource} label="Sources" items={props.filters!.sources} mode="multiple"
                onChange={(sources, isSource): void => {
                  props.onSetFilters({ ...props.filters, sources, isSource });
                }}></YcFieldCheckPage>
            </Form.Item>}
            <Form.Item>
              <YcFieldCheckPage is={props.filters!.isPlatform} label="Platforms" items={props.filters!.platforms} mode="multiple"
                onChange={(platforms, isPlatform): void => {
                  props.onSetFilters({ ...props.filters, platforms, isPlatform });
                }}></YcFieldCheckPage>
            </Form.Item>
            <Form.Item>
              <YcFieldCheckPage is={props.filters!.isPlatformVersion} label="Platform Versions" items={props.filters!.platformVersions} mode="multiple"
                onChange={(platformVersions, isPlatformVersion): void => {
                  props.onSetFilters({ ...props.filters, platformVersions, isPlatformVersion });
                }}></YcFieldCheckPage>
            </Form.Item>
            {props.type === 'advertising' &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isApplication} label="Applications" items={props.filters!.applications} mode="multiple"
                  onChange={(applications, isApplication): void => {
                    props.onSetFilters({ ...props.filters, applications, isApplication });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {props.type === 'advertising' &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isCampaign} label="Campaigns" items={props.filters!.campaigns} mode="multiple"
                  onChange={(campaigns, isCampaign): void => {
                    props.onSetFilters({ ...props.filters, campaigns, isCampaign });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {props.type === 'advertising' && canSeePlacementTypeAdv &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isPlacementType} label="Placement Types" items={props.filters!.placementTypes} mode="multiple"
                  onChange={(placementTypes, isPlacementType): void => {
                    props.onSetFilters({ ...props.filters, placementTypes, isPlacementType });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {props.type === 'advertising' && canSeeCreativeTypeAdv &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isCreativeType} label="Creative Types" items={props.filters!.creativeTypes} mode="multiple"
                  onChange={(creativeTypes, isCreativeType): void => {
                    props.onSetFilters({ ...props.filters, creativeTypes, isCreativeType });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {props.type === 'advertising' && canSeeAdAdv &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isAd} label="Ads" items={[]} disabledSelect={true}
                  onChange={(ads, isAd): void => {
                    props.onSetFilters({ ...props.filters, ads, isAd });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {props.type === 'publishing' &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isPlacement} label="Placements" items={props.filters!.placements} mode="multiple"
                  onChange={(placements, isPlacement): void => {
                    props.onSetFilters({ ...props.filters, placements, isPlacement });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {props.type !== 'advertising' &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isPlacementType} label="Placement Types" items={props.filters!.placementTypes} mode="multiple"
                  onChange={(placementTypes, isPlacementType): void => {
                    props.onSetFilters({ ...props.filters, placementTypes, isPlacementType });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            {(props.type === 'bidding') &&
              <Form.Item>
                <YcFieldCheckPage is={props.filters!.isLossCode} label="Loss Codes" items={props.filters!.lossCodes} mode="multiple"
                  onChange={(lossCodes, isLossCode): void => {
                    props.onSetFilters({ ...props.filters, lossCodes, isLossCode });
                  }}></YcFieldCheckPage>
              </Form.Item>
            }
            <Form.Item>
              <Button type="primary" onClick={() => {
                uHistory.push({
                  search: getParams(true) as string,
                });
                props.onSearch(getParams(false));
              }}>Search</Button>
            </Form.Item>
          </Form>
        }
      </Spin>
    </div>
  );
}